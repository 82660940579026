import React, { useRef, useEffect } from "react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";

function FillTableMenu({
  handleShow,
  showSetting,
  admin,
  onEditEmployee,
  onDeleteEmployee,
  setShow,
}) {
  const menuRef = useRef();

  // HANDLE SHOW MENU
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setShow]);

  // IF CLICK INNNER DIV STOP ONCLIK ALL SIDE EXCEPT INNER
  const handleInnerDivClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div>
      <EllipsisVerticalIcon onClick={handleShow} className={"w-5 h-5"} aria-hidden="true"  />
      {showSetting && (
        <div
          ref={menuRef}
          className="absolute top-6 right-0 bg-white w-[250px] h-max p-5 z-50 border-[2px] border-gray-300 shadow-md rounded-md flex justify-center items-center "
        >
          <div
            className="flex flex-col gap-3 w-full"
            onClick={handleInnerDivClick}
          >
            {/* <div
              // onClick={viewDetail}
              className="hover:text-secondary duration-300 transition-shadow"
            >
              View Details
            </div> */}

            {admin && (
              <>
                {/* <div
                  // onClick={handleResign}
                  className="hover:text-secondary duration-300 transition-shadow"
                >
                  Resign
                </div> */}
                <div
                  onClick={onEditEmployee}
                  className="hover:text-secondary duration-300 transition-shadow"
                >
                  Edit
                </div>

                <div
                  onClick={onDeleteEmployee}
                  className="hover:text-secondary duration-300 transition-shadow"
                >
                  Delete
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default FillTableMenu;
