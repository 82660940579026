import React from "react";
import {
  LeaveTypeSettingsForm,
} from "@bluesilodev/timhutcomponents";
import { useDispatch } from "react-redux";
import { updateLeaveData, updateEditLeaveData } from "store/employeeSlice";

function FormLeave({ data, showModal, setShowModal }) {
  // const validationSchema = yup.object().shape({
  //   name: yup.string().required("Leave Name is Required"),
  //   defaultAmount: yup.string().required("Default Amount is Required"),
  //   expiredCarryOver: yup.string().required("Expired Carry Over is Required"),
  //   maxCarryOver: yup.string().required("Max Carry Over is Required"),
  //   periodOfEmployments: yup.array().of(
  //     yup.object().shape({
  //       from: yup.string().required("from 1 is Required"),
  //       to: yup.string().required("to 1 is Required"),
  //       equal: yup.string().required("equal 1 is Required"),
  //     })
  //   ),
  // });

  const dispatch = useDispatch();

  const initialVal = {
    name: data.name,
    gainPerMonth: data.gainPerMonth,
    expiredCarryOver: parseInt(data.expiredCarryOver),
    defaultAmount: parseInt(data.defaultAmount),
    maxCarryOver: parseInt(data.maxCarryOver),
    periodOfEmployments: data.periodOfEmployments,
  };

  return (
    <div className="w-full">
      {showModal && (
        <LeaveTypeSettingsForm
          initialValue={initialVal}
          onSubmit={(val) => {
            const allVal = {
              ...val,
              organizationID: data.organizationID,
              uId: data.uId,
            };

            // STATE ADD EMPLOYEE
            dispatch(updateLeaveData(allVal));

            // STATE EDIT EMPLOYEE
            dispatch(updateEditLeaveData(allVal));

            setShowModal(false);
          }}
          visibleModal={showModal}
          setVisibleModal={setShowModal}
        />
      )}
    </div>
  );
}

export default FormLeave;
