import React from "react";
import { useFormikContext } from "formik";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {
  ProfilePicture,
  Address,
  Education,
  Hobbies,
  Languages,
  PersonalInformation,
  Skills,
  UserCredential,
} from "pages/shared/formUser/personalInfo";

import "../../../../node_modules/flag-icons/css/flag-icons.min.css";
import { setAccountStudy } from "store/employeeSlice";

function PersonalInfo({ countries }) {
  const dispatch = useDispatch();
  const {
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext();

  const { accountStudy } = useSelector((state) => state.employeeSlice);

  const setFormikValRedux = (updatedFormikVal) => {
    dispatch(setAccountStudy(updatedFormikVal));
  };

  return (
    <div className="w-full flex flex-col gap-4 overflow-y-auto h-max py-5">
      <ProfilePicture
        values={values}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
        fieldPhoto={"photo"}
      />
      <PersonalInformation
        values={values}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
      />
      <UserCredential
        values={values}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
      />
      <Education formikVal={accountStudy} setFormikVal={setFormikValRedux} />
      <Address
        values={values}
        countries={countries}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
      />
      <Hobbies
        values={values}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
      />
      <Skills
        values={values}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
      />
      <Languages
        values={values}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
      />
    </div>
  );
}

PersonalInfo.propTypes = {
  countries: PropTypes.array,
};

PersonalInfo.defaultProps = {
  countries: [],
};

export default PersonalInfo;
