import React from "react";
import PropTypes from "prop-types";

function FiLeft({ color, className, ...props }) {
  return (
    <svg
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
      {...props}
    >
      <path
        d="M8 4.5C9.10449 4.5 10 3.60456 10 2.5C10 1.39544 9.10449 0.5 8 0.5C6.89551 0.5 6 1.39544 6 2.5C6 3.60456 6.89551 4.5 8 4.5Z"
        fill={color}
      />
      <path
        d="M10 8.48438C10 9.58894 9.10449 10.4844 8 10.4844C6.89551 10.4844 6 9.58894 6 8.48438C6 7.37981 6.89551 6.48438 8 6.48438C9.10449 6.48438 10 7.37981 10 8.48438Z"
        fill={color}
      />
      <path
        d="M10 14.5C10 15.6046 9.10449 16.5 8 16.5C6.89551 16.5 6 15.6046 6 14.5C6 13.3954 6.89551 12.5 8 12.5C9.10449 12.5 10 13.3954 10 14.5Z"
        fill={color}
      />
    </svg>
  );
}

FiLeft.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

FiLeft.defaultProps = {
  color: "black",
  className: "w-[20px] h-[20px]",
};

export default FiLeft;
