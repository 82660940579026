import React from "react";
import {
  InputText,
  InputSelect,
  CountrySelect,
} from "@bluesilodev/timhutcomponents";

function ChildModal({
  title,
  subtitle,
  photo,
  firstName,
  lastName,
  email,
  location,
  department,
  jobPosition,
  phoneNumber,
}) {
  return (
    <div className="w-full p-1 flex justify-center items-center flex-col">
      <div className="flex gap-1 font-semibold ">
        <h1>
          {title} {">"}
          {"  "}
        </h1>
        <p>{subtitle + "  "} </p>
      </div>

      <div className="border-[4px] border-white shadow-xl rounded-full flex justify-center items-center mt-2">
        <div
          style={{ backgroundImage: `url(${photo})` }}
          className="w-[174px] h-[174px] rounded-full cursor-pointer bg-center"
        ></div>
      </div>

      <div className="flex flex-col gap-2 w-full">
        <div className="w-full flex gap-3 mt-5">
          <InputText
            title={"First Name"}
            value={firstName}
            disabled={true}
            required={true}
          />
          <InputText
            title={"Last Name"}
            value={lastName}
            disabled={true}
            required={true}
          />
        </div>

        <div className="w-full flex gap-3">
          <InputText
            title={"Email"}
            value={email}
            disabled={true}
            required={true}
          />
          <InputSelect
            title={"Locations"}
            value={location}
            disabled={true}
            required={true}
            options={[
              {
                label: `${location}`,
                value: `${location}`,
              },
            ]}
          />
        </div>

        <div className="w-full flex gap-3">
          <InputSelect
            title={"Department"}
            value={department}
            disabled={true}
            required={true}
            options={[
              {
                label: `${department}`,
                value: `${department}`,
              },
            ]}
          />
          <InputSelect
            title={"Job Positions"}
            value={jobPosition}
            disabled={true}
            required={true}
            options={[
              {
                label: `${jobPosition}`,
                value: `${jobPosition}`,
              },
            ]}
          />
        </div>
        <div className="w-full flex gap-2">
          <CountrySelect disabled={true} />
          <InputText
            title={"Phone Number"}
            value={phoneNumber}
            disabled={true}
            required={true}
          />
        </div>
      </div>
    </div>
  );
}

export default ChildModal;
