import React from "react";
import PropTypes from "prop-types";

function FiSaveSvg({className, color, ...props}) {
  return (
    <svg
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
      {...props}
    >
      <path
        d="M13.1667 14.5H3.83333C3.47971 14.5 3.14057 14.3595 2.89052 14.1095C2.64048 13.8594 2.5 13.5203 2.5 13.1667V3.83333C2.5 3.47971 2.64048 3.14057 2.89052 2.89052C3.14057 2.64048 3.47971 2.5 3.83333 2.5H11.1667L14.5 5.83333V13.1667C14.5 13.5203 14.3595 13.8594 14.1095 14.1095C13.8594 14.3595 13.5203 14.5 13.1667 14.5Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.8333 14.4993V9.16602H5.16666V14.4993"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.16666 2.5V5.83333H10.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

FiSaveSvg.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

FiSaveSvg.defaultProps = {
  color: "white",
  className: "w-[20px] h-[20px]",
};

export default FiSaveSvg;
