import React from "react";

function Switch() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 8L3.46967 7.46967L2.93934 8L3.46967 8.53033L4 8ZM19.25 10C19.25 10.4142 19.5858 10.75 20 10.75C20.4142 10.75 20.75 10.4142 20.75 10H19.25ZM7.46967 3.46967L3.46967 7.46967L4.53033 8.53033L8.53033 4.53033L7.46967 3.46967ZM3.46967 8.53033L7.46967 12.5303L8.53033 11.4697L4.53033 7.46967L3.46967 8.53033ZM4 8.75H18V7.25H4V8.75ZM18 8.75C18.6904 8.75 19.25 9.30964 19.25 10H20.75C20.75 8.48122 19.5188 7.25 18 7.25V8.75Z"
        fill="#1A1A2E"
      />
      <path
        d="M20 16L20.5303 15.4697L21.0607 16L20.5303 16.5303L20 16ZM5 16L5 16.75L5 16.75L5 16ZM2.25 14C2.25 13.5858 2.58579 13.25 3 13.25C3.41421 13.25 3.75 13.5858 3.75 14L2.25 14ZM16.5303 11.4697L20.5303 15.4697L19.4697 16.5303L15.4697 12.5303L16.5303 11.4697ZM20.5303 16.5303L16.5303 20.5303L15.4697 19.4697L19.4697 15.4697L20.5303 16.5303ZM20 16.75L5 16.75L5 15.25L20 15.25L20 16.75ZM5 16.75C3.48122 16.75 2.25 15.5188 2.25 14L3.75 14C3.75 14.6904 4.30964 15.25 5 15.25L5 16.75Z"
        fill="#1A1A2E"
      />
    </svg>
  );
}

export default Switch;
