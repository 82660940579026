import React from "react";

function DateDisplay({ dateStr }) {
  if (!dateStr) {
    return <div>-</div>;
  }

  const date = new Date(dateStr);

  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };

  const formattedDate = date.toLocaleDateString("en-GB", options);

  return (
    <div>
      <div>{formattedDate}</div>
    </div>
  );
}

export default DateDisplay;

//   const formattedTime = date.toLocaleTimeString("en-US", {
//     hour: "2-digit",
//     minute: "2-digit",
//     hour12: false,
//   });
