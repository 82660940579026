import React, { useState } from "react";
import { FiZoomIn, FiZoomOut } from "components/icons";

function DragLeftAndRight({ children }) {
  // STATE FOR HANDLE POSITION
  const [positionX, setPositionX] = useState(0);
  const [dragging, setDragging] = useState(false);
  const [lastMouseX, setLastMouseX] = useState(0);
  // STATE ZOOM OUT AND ZOOM IN
  const [scale, setScale] = useState(1);

  // HANDLE MOUSE
  const handleMouseDown = (e) => {
    setDragging(true);
    setLastMouseX(e.pageX);
  };

  const handleMouseMove = (e) => {
    if (dragging) {
      e.preventDefault();
      const newPositionX = positionX + e.pageX - lastMouseX;

      // MAKESURE posisiX still in the 0 untill -300
      if (newPositionX > 0) {
        setPositionX(0); // if more than 0, set to 0
      } else if (newPositionX < -1000) {
        setPositionX(-1000); // if less than -300, set to -300
      } else {
        setPositionX(newPositionX); // if between 0 and -300, set to newPositionX
      }

      setLastMouseX(e.pageX);
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  const handleZoomIn = () => {
    console.log("zoom in ");
    setScale(scale + 0.1); // ADD SCALE 0.1
  };

  const handleZoomOut = () => {
    setScale(scale - 0.1); // LESS SCALE 0.1
  };

  return (
    <div className="flex w-full relative">
      <div className="w-max overflow-auto cursor-grab z-0 absolute left-0 top-0 h-screen ">
        <div
          style={{
            transform: `translateX(${positionX}px) `,
            transition: dragging ? "none" : "transform 0.3s ease",
            cursor: dragging ? "grabbing" : "grab",
          }}
          // onMouseDown={handleMouseDown}
          // onMouseMove={handleMouseMove}
          // onMouseUp={handleMouseUp}
          // onMouseLeave={handleMouseUp}
          className="flex gap-5 w-full h-full p-5 "
        >
          <div
            style={{
              transform: `scale(${scale})`,
            }}
          >
            {children}
          </div>
        </div>
      </div>

      {/* SCALE */}
      <div className="fixed top-[200px] right-[50px] z-30">
        <div className="flex gap-3 ">
          <div
            onClick={handleZoomIn}
            className="border-[1px] border-black p-3 rounded-md cursor-pointer"
          >
            <FiZoomIn />
          </div>
          <div
            onClick={handleZoomOut}
            className="border-[1px] border-black p-3 rounded-md cursor-pointer"
          >
            <FiZoomOut />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DragLeftAndRight;
