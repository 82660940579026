import React from "react";
import {
  Accordion,
  InputText,
  InputSelect,
  InputDate,
  CountrySelect,
} from "@bluesilodev/timhutcomponents";

function PersonalInformation({
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
  setFieldValue,
}) {
  return (
    <div>
      {/* PERSONAL INFORMATION */}
      <div className="w-full rounded-md ">
        <Accordion
          icons={[]}
          title={"Personal Information"}
          children={
            <div className="w-full flex flex-wrap gap-3 pt-3">
              <div className="w-full flex gap-3">
                <InputText
                  title={"First Name"}
                  className={"flex items-center gap-2 w-full"}
                  required={true}
                  label={null}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName}
                  id={"firstName"}
                  error={
                    <div>
                      {errors.firstName &&
                        touched.firstName &&
                        errors.firstName}
                    </div>
                  }
                />
                <InputText
                  title={"Last Name"}
                  className={"flex items-center gap-2 w-full"}
                  required={true}
                  label={null}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastName}
                  id={"lastName"}
                  error={
                    <div>
                      {errors.lastName && touched.lastName && errors.lastName}
                    </div>
                  }
                />

                <InputSelect
                  options={[
                    { label: "Male", value: "Male" },
                    { label: "Female", value: "Female" },
                  ]}
                  required={true}
                  classname={"w-full"}
                  title={"Gender"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.gender}
                  id={"gender"}
                  error={
                    errors.gender &&
                    touched.gender && <div>{errors.gender}</div>
                  }
                />
              </div>

              <div className="flex gap-3 w-full">
                <InputSelect
                  options={[
                    { label: "Christianity", value: "Christianity" },
                    { label: "Islam", value: "Islam" },
                    { label: "Folk Religions", value: "Folk Religions" },
                    { label: "Hinduism", value: "Hinduism" },
                    { label: "Buddhism", value: "Buddhism" },
                    { label: "No Religion", value: "No Religion" },
                    { label: "Other", value: "Other" },
                  ]}
                  required={true}
                  title={"Religion"}
                  classname={"w-full"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.religions}
                  id={"religions"}
                  error={
                    <div>
                      {errors.religions &&
                        touched.religions &&
                        errors.religions}
                    </div>
                  }
                />
                <InputDate
                  label={"Date Of Birth"}
                  name={"dateOfBirth"}
                  required={true}
                  setFieldValue={setFieldValue}
                  value={values.dateOfBirth}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={
                    errors.dateOfBirth &&
                    touched.dateOfBirth && (
                      <div className="text-red-500 text-sm">
                        {errors.dateOfBirth}
                      </div>
                    )
                  }
                />

                <InputSelect
                  options={[
                    { label: "Single", value: "Single" },
                    { label: "Married", value: "Married" },
                  ]}
                  required={true}
                  title={"Marital Status"}
                  classname={"w-full"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.maritalStatus}
                  id={"maritalStatus"}
                  error={
                    <>
                      {errors.maritalStatus && touched.maritalStatus && (
                        <div>{errors.maritalStatus}</div>
                      )}
                    </>
                  }
                />
              </div>

              <div className="flex w-full gap-3 ">
                <div className="w-[33%]">
                  <InputSelect
                    options={[
                      { label: "A", value: "A" },
                      { label: "B", value: "B" },
                      { label: "O", value: "O" },
                      { label: "A+", value: "A+" },
                      { label: "B+", value: "B+" },
                      { label: "O-", value: "O-" },
                      { label: "AB", value: "AB" },
                      { label: "AB+", value: "AB+" },
                      { label: "AB-", value: "AB-" },
                    ]}
                    required={true}
                    title={"Blood Type"}
                    classname={""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.bloodTypes}
                    id={"bloodTypes"}
                    error={
                      <div>
                        {errors.bloodTypes &&
                          touched.bloodTypes &&
                          errors.bloodTypes}
                      </div>
                    }
                  />
                </div>

                <div className="w-[67%] flex gap-3">
                  <CountrySelect
                    isTitle={false}
                    classname={"w-[100px] border-[1px] border-black"}
                    onChange={(value) => setFieldValue("countryPhoneNumber", value.code)}
                    value={`${values.countryPhoneNumber}`}
                  />

                  <InputText
                    type={"number"}
                    className={"flex items-center gap-2 w-full "}
                    required={true}
                    title={"Phone Number"}
                    label={null}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phoneNumber}
                    id={"phoneNumber"}
                    error={
                      <div>
                        {errors.phoneNumber &&
                          touched.phoneNumber &&
                          errors.phoneNumber}
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
}

export default PersonalInformation;
