import React from 'react'

function Salary() {
  return (
    <div>
      SALARY 
    </div>
  )
}

export default Salary
