import React from "react";
import PropTypes from "prop-types";
import { CloseRoundSvg } from "components/icons";

function SelectChoice({ title, icon, handleDelete, ...props }) {
  return (
    <div className="text-orange-500 border-[1px] border-orange-500 w-max h-[40px] rounded-md flex justify-center items-center">
      <div className="flex items-center text-sm p-2 gap-2">
        <h1> {title} </h1>
        <div className="cursor-pointer" onClick={handleDelete} {...props}>
          {icon}
        </div>
      </div>
    </div>
  );
}

SelectChoice.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.node,
  handleDelete: PropTypes.func,
};

SelectChoice.defaultProps = {
  title: "",
  icon: <CloseRoundSvg />,
  handleDelete: () => {},
};

export default SelectChoice;
