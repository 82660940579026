import React from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { useCreateEvent } from "service/eventAPI";

import FormEventShared from "pages/shared/formEvent";
import { alertSuccess, alertError } from "utils/alert";

import { eventCreateSchema } from "./eventSchema";

function CreateEvent() {
  const queryClient = useQueryClient();
  const payload = JSON.parse(localStorage.getItem("payload")) || "";
  const navigate = useNavigate();

  const { mutate, isPending } = useCreateEvent({
    onSuccess: () => {
      queryClient.invalidateQueries("getEvent");
      alertSuccess("Created Success");
      navigate("/event");
    },
    onError: () => {
      alertError("Something wrong!");
    },
  });

  const handleSubmit = (val, { resetForm }) => {
    
    const [day, month, year] = val.startDate.split("/");
    const [dd, mm, yy] = val.endDate.split("/");

    const startDate = new Date(year, month - 1, day);
    const endDate = new Date(yy, mm - 1, dd);

    const form = new FormData();
    form.append("organizationID", val.organizationID);
    form.append("duration", val.duration);
    form.append("nameEvent", val.nameEvent);
    form.append("startDate", startDate);
    form.append("endDate", endDate);
    form.append("isCompanyHoliday", val.isCompanyHoliday);
    form.append("posterAttachment", val.posterAttachment);
    form.append("note", val.note);
    form.append("location", val.location);

    mutate(form);
    resetForm();
  };

  if (isPending) {
    return <div>Loading...</div>;
  }

  const initialValues = {
    organizationID: payload?.payLoad?.organizationID,
    nameEvent: "",
    startDate: "",
    endDate: "",
    duration: 0,
    isCompanyHoliday: false,
    posterAttachment: "",
    note: "",
    location: "",
  };

  return (
    <FormEventShared
      initialValues={initialValues}
      validationSchema={eventCreateSchema}
      handleSubmitForm={handleSubmit}
    />
  );
}

export default CreateEvent;
