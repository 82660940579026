import React, { useEffect } from "react";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";

import { setLogoutReducer } from "store/userLoginSlice";
import {
  UserIcon,
  FiHeart,
  Switch,
  PreviumSvg,
  LocationSvg,
  ChevronSvg,
  IconSetting,
  FiHome,
  Fi2User,
  FiUserX,
  FiArrowLeft,
} from "components/icons";

import LayoutForm from "pages/shared/layout/LayoutForm";

function LayoutUserSetting() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // LOGOUT
  function logOut() {
    dispatch(setLogoutReducer());
    window.location.href = "/login";
    window.localStorage.clear();
    return null;
  }

  const locationNav = ["/location", "/createLocation", "/editLocation"];

  const navLocation = locationNav.some((path) =>
    location.pathname.startsWith(path)
  );

  const employeeStatus = [
    "/editEmployeeStatus",
    "/createEmployeeStatus",
    "/employeeStatus",
  ];

  const navEmployeeStatus = employeeStatus.some((path) =>
    location.pathname.startsWith(path)
  );

  const event = ["/event", "/createEvent", "/editEvent"];

  const navEvent = event.some((path) => location.pathname.startsWith(path));

  // CREATE MENU SIDEBAR
  const allMenu = [
    {
      label: (
        <NavLink
          to={"/myAccount"}
          // onClick={() => setPathUserSetting("My Account")}
          className={`${
            ["/myAccount"].includes(location.pathname) &&
            "bg-orange-500 text-white"
          } flex gap-1 rounded-md p-3 w-full items-center hover:scale-x-105 hover:cursor-pointer transition-all duration-300`}
        >
          <UserIcon
            color={`${location.pathname === "/myAccount" ? "white" : "black"}`}
          />
          <h1>My Account</h1>
        </NavLink>
      ),
    },
    {
      label: (
        <NavLink
          to={"/companyDetail"}
          // onClick={() => setPathUserSetting("Company Details")}
          className={`${
            location.pathname === "/companyDetail" && "bg-orange-500 text-white"
          } flex gap-1 rounded-md p-3 w-full items-center hover:scale-x-105 hover:cursor-pointer transition-all duration-300`}
        >
          <FiHome
            color={`${
              location.pathname === "/companyDetail" ? "white" : "black"
            }`}
          />
          <h1>Company Details</h1>
        </NavLink>
      ),
    },
    {
      label: (
        <NavLink
          to={"/location"}
          className={`${
            navLocation && "bg-orange-500 text-white"
          } flex gap-1 rounded-md p-3 w-full items-center hover:scale-x-105 hover:cursor-pointer transition-all duration-300`}
        >
          <LocationSvg color={`${navLocation ? "white" : "black"}`} />
          <h1>Locations</h1>
        </NavLink>
      ),
    },
    {
      label: (
        <NavLink
          to={"/employeeStatus"}
          // onClick={() => setPathUserSetting("Employee Status")}
          className={`${
            navEmployeeStatus && "bg-orange-500 text-white"
          } flex gap-1 rounded-md p-3 w-full items-center hover:scale-x-105 hover:cursor-pointer transition-all duration-300`}
        >
          <Fi2User color={`${navEmployeeStatus ? "white" : "black"}`} />
          <h1>Employee Status</h1>
        </NavLink>
      ),
    },
    {
      label: (
        <NavLink
          to={"/event"}
          // onClick={() => setPathUserSetting("Event")}
          className={`${
            navEvent && "bg-orange-500 text-white"
          } flex gap-1 rounded-md p-3 w-full items-center hover:scale-x-105 hover:cursor-pointer transition-all duration-300`}
        >
          <FiHeart color={`${navEvent ? "white" : "black"}`} />
          <h1>Events</h1>
        </NavLink>
      ),
    },
    /*
    {
      label: (
        <NavLink
          to={"/resignNotice"}
          // onClick={() => setPathUserSetting("Resign Notice")}
          className={`${
            location.pathname === "/resignNotice" && "bg-orange-500 text-white"
          } flex gap-1 rounded-md p-3 w-full items-center hover:scale-x-105 hover:cursor-pointer transition-all duration-300`}
        >
          <FiUserX
            color={`${
              location.pathname === "/resignNotice" ? "white" : "black"
            }`}
          />
          <h1>Resign Notice Period</h1>
        </NavLink>
      ),
    },
    */
  ];

  const itemSetting = [
    {
      label: (
        <NavLink
          to={"/myAccount"}
          className="py-1 flex items-center hover:bg-gray-100 cursor-pointer"
          role="none"
        >
          <UserIcon color={"black"} />
          <h1 className="text-gray-700 block px-2 py-2 text-xs ">My Account</h1>
        </NavLink>
      ),
    },
    {
      label: (
        <NavLink
          to={"/setting"}
          className=" flex items-center hover:bg-gray-100 cursor-pointer py-1"
          role="none"
        >
          <IconSetting />
          <div
            className="text-gray-700 block px-2 py-2 text-xs"
            tabIndex="-1"
            id="menu-item-3"
          >
            Settings
          </div>
        </NavLink>
      ),
    },
    {
      label: (
        <div
          className="flex items-center gap-1 hover:bg-gray-100 cursor-pointer py-1"
          role="none"
        >
          <Switch />
          <div className="w-full flex justify-between">
            <div className="flex flex-col items-start justify-start ">
              <h1
                className="text-gray-700 block text-xs"
                role="menuitem"
                tabIndex="-1"
                id="menu-item-3"
              >
                Language
              </h1>
              <p
                className="text-gray-700  text-xs font-bold"
                role="menuitem"
                tabIndex="-1"
                id="menu-item-3"
              >
                English
              </p>
            </div>

            <div className="mt-3">
              <ChevronSvg />
            </div>
          </div>
        </div>
      ),
    },
    {
      label: (
        <NavLink
          to={"/currentPlan"}
          className=" flex gap-1 items-center hover:bg-gray-100 cursor-pointer py-1"
          role="none"
        >
          <PreviumSvg />
          <div className="flex flex-col">
            <h1
              className="text-gray-700 text-xs"
              role="menuitem"
              tabIndex="-1"
              id="menu-item-3"
            >
              Current Plan
            </h1>
            <p
              className="text-gray-700  text-xs font-bold"
              role="menuitem"
              tabIndex="-1"
              id="menu-item-3"
            >
              Free Trial (14 Days Left)
            </p>
          </div>
        </NavLink>
      ),
    },
    {
      label: (
        <div
          className=" flex gap-1 items-center hover:bg-gray-100 cursor-pointer py-1"
          role="none"
          onClick={logOut}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-[20px] h-[20px]"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
            />
          </svg>
          <div
            className="text-gray-700  text-xs"
            role="menuitem"
            tabIndex="-1"
            id="menu-item-3"
          >
            Log Out
          </div>
        </div>
      ),
    },
  ];

  const handleBackLocation = () => {
    navigate("/location");
  };

  const handleBackEmployeeStatus = () => {
    navigate("/employeeStatus");
  };

  const handleBackEvent = () => {
    navigate("/event");
  };

  const handleTitle = () => {
    if (location.pathname === "/myAccount") {
      return <h1 className="text-[24px]">My Account</h1>;
    } else if (location.pathname === "/companyDetail") {
      return <h1 className="text-[24px]">Company Details</h1>;
    } else if (location.pathname === "/location") {
      return <h1 className="text-[24px]">Location</h1>;
    } else if (location.pathname === "/employeeStatus") {
      return <h1 className="text-[24px]">Employee Status</h1>;
    } else if (location.pathname === "/event") {
      return <h1 className="text-[24px]">Event</h1>;
    } else if (location.pathname === "/resignNotice") {
      return <h1 className="text-[24px]">Resign Notice</h1>;
    }

    if (location.pathname === "/createLocation") {
      return (
        <div
          onClick={handleBackLocation}
          className={"flex gap-2 items-center cursor-pointer"}
        >
          <FiArrowLeft />
          <h1 className="text-[24px]">Create Location</h1>
        </div>
      );
    } else if (location.pathname === "/createEmployeeStatus") {
      return (
        <div
          onClick={handleBackEmployeeStatus}
          className={"flex gap-2 items-center cursor-pointer"}
        >
          <FiArrowLeft />
          <div className="text-[24px]">Create Employee Status</div>
        </div>
      );
    } else if (location.pathname === "/createEvent") {
      return (
        <div
          onClick={handleBackEvent}
          className={"flex gap-2 items-center cursor-pointer"}
        >
          <FiArrowLeft />
          <h1 className="text-[24px]">Create Events</h1>
        </div>
      );
    } else if (navLocation) {
      return (
        <div
          onClick={handleBackLocation}
          className={"flex gap-2 items-center cursor-pointer"}
        >
          <FiArrowLeft />
          <h1 className="text-[24px]">Edit Location</h1>
        </div>
      );
    }
    if (navEmployeeStatus) {
      return (
        <div
          onClick={handleBackEmployeeStatus}
          className={"flex gap-2 items-center cursor-pointer"}
        >
          <FiArrowLeft />
          <h1 className="text-[24px]">Edit EmployeeStatus</h1>
        </div>
      );
    }
    if (navEvent) {
      return (
        <div
          onClick={handleBackEvent}
          className={"flex gap-2 items-center cursor-pointer"}
        >
          <FiArrowLeft />
          <h1 className="text-[24px]">Edit Event</h1>
        </div>
      );
    }
  };

  return (
    <>
      <LayoutForm
        allMenu={allMenu}
        handleTitle={handleTitle}
        itemSetting={itemSetting}
      />
    </>
  );
}

export default LayoutUserSetting;
