import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { ModalConfirmation } from "@bluesilodev/timhutcomponents";

import { useDeleteUser } from "service/userAPI";
import { alertSuccess, alertError } from "utils/alert";
import { adminAndSuperVisorRole } from "utils/roleFromLocalStorage";

import FillTableMenu from "components/FillTableMenu";

function TableMenu({ data }) {
  const [show, setShow] = useState(false);
  const [uIdData, setuIdData] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleShow = () => {
    setShow(!show);
  };

  const onEditEmployee = (uId) => {
    navigate(`/editEmployee/${uId}`, { replace: true });
  };

  // DELETE USER
  const { mutate, isPending } = useDeleteUser(uIdData, {
    onSuccess: () => {
      queryClient.invalidateQueries("getUser");
      alertSuccess("SUCCESS DELETED DATA");
    },
    onError: () => {
      alertError("FAILED DELETE DATA!");
    },
  });

  const onDeleteEmployee = (uId) => {
    setuIdData(uId);
    setShowModal(true);
  };

  const confirmModal = () => {
    mutate(uIdData);
    setShowModal(false);
    setShow(false);
  };

  const cancelModal = () => {
    setShowModal(false);
    setShow(false);
  };

  if (isPending) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="relative">
        <FillTableMenu
          admin={adminAndSuperVisorRole}
          handleShow={handleShow}
          onDeleteEmployee={() => onDeleteEmployee(data?.uId)}
          onEditEmployee={() => onEditEmployee(data?.uId)}
          showSetting={show}
          setShow={setShow}
        />

        {showModal && (
          <ModalConfirmation onCancel={cancelModal} onConfirm={confirmModal} />
        )}
      </div>
    </>
  );
}

export default TableMenu;
