import React from "react";
import PropTypes from "prop-types";

function ChevronLeft({color, className, ...props }) {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
      {...props}
    >
      <path
        d="M10 12L6 8L10 4"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

ChevronLeft.propTypes = {
  className: PropTypes.string,
  color:PropTypes.string
};

ChevronLeft.defaultProps = {
  className: "w-[16px] h-[16px]",
  color: "black"
};

export default ChevronLeft;
