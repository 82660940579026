import React, { useState } from "react";
import { InputSelect } from "@bluesilodev/timhutcomponents";
import TreeOrganization from "./TreeOrganization";
import { useGetAllLocation } from "service/locationAPI";
import { useQueryClient } from "@tanstack/react-query";

function OrganizationPage() {
  const [selectLocation, setSelectLocation] = useState("");
  const [selectDepartment, setSelectDepartment] = useState("");

  const queryClient = useQueryClient();

  // GET LOCATION
  const { data: dataLocation, isPending } = useGetAllLocation({
    onSuccess: () => {
      queryClient.invalidateQueries("getLocation");
    },
    onError: (err) => {
      console.log(err);
    },
  });

  if (isPending) {
    return <div>Loading...</div>;
  }

  // MAPPING LOCATION
  const locationData =
    dataLocation &&
    dataLocation.data.map((item, index) => {
      return {
        value: item.locationName,
        label: item.locationName,
      };
    });

  // MAPPING DEPARTMENT
  const departmentData =
    dataLocation &&
    [...new Set(dataLocation.data.filter((item) => selectLocation ? item.locationName === selectLocation : true).flatMap((entry) => entry.departments).map((item) => item?.department))].map((item, index) => {
      return {
        label: item,
        value: item,
      };
    });

  return (
    <div className="w-full mt-10">
      <div className="flex gap-3 w-[40%] pb-3">
        <InputSelect
          title={"Locations"}
          id="option1"
          options={locationData}
          value={selectLocation}
          onChange={(e) => setSelectLocation(e.target.value)}
        />
        <InputSelect
          title={"Department"}
          id="option2"
          options={departmentData}
          value={selectDepartment}
          onChange={(e) => setSelectDepartment(e.target.value)}
        />{" "}
      </div>

      <TreeOrganization
        department={selectDepartment}
        location={selectLocation}
      />
    </div>
  );
}

export default OrganizationPage;
