import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import forgotPasswordSlice from "./forgotPasswordSlice";
import loginSlice from "./userLoginSlice";
import userInformSlice from "./userInformSlice";
import comunitySlice from "./comunitySlice";
import employeeSlice from "./employeeSlice";

const persistConfig = {
	key: "root",
	storage,
	// blacklist: ["userData"],
};

const rootReducer = combineReducers({
	forgotPasswordSlice,
	loginSlice,
	employeeSlice,
	user: userInformSlice,
	comunitySlice
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: [thunk],
});

// export default configureStore({
// 	reducer: persistReducer
// });

export const persistor = persistStore(store);