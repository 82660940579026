import React from "react";
import { useFormikContext } from "formik";
import PaymentDetailShared from "pages/shared/formUser/paymentDetail";

function PaymentDetails() {
  const {
    errors,
    values,
    handleBlur,
    handleChange,
    setFieldTouched,
    setFieldValue,
    touched,
  } = useFormikContext();

  return (
    <>
      <PaymentDetailShared
        errors={errors}
        values={values}
        handleBlur={handleBlur}
        handleChange={handleChange}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        touched={touched}
      />
    </>
  );
}

export default PaymentDetails;
