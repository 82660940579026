import React from "react";
import {
  Accordion,
  InputText,
  Button,
  ToggleSwitch,
} from "@bluesilodev/timhutcomponents";

import { FiSaveSvg } from "components/icons";

function FormSharedEmployeeStatus({
  handleSubmit,
  handleBlur,
  handleChange,
  values,
  errors,
  touched,
}) {
  return (
    <form onSubmit={handleSubmit} className="pt-10" autoComplete="off">
      <div className="w-full flex justify-end">
        <Button
          style="solid"
          className={"w-[200px]"}
          type={"submit"}
          label={
            <div className="flex gap-2">
              <FiSaveSvg />
              <div>Save</div>
            </div>
          }
        />
      </div>

      <Accordion
        title={"Employee Status"}
        icons={[]}
        children={
          <div className="w-[70%] pt-5 flex flex-col gap-3">
            <div className="flex gap-3 w-full">
              <InputText
                id="employeeType"
                title={"Employee Type"}
                label={null}
                value={values.employeeType}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  <div>
                    {errors.employeeType &&
                      touched.employeeType &&
                      errors.employeeType}
                  </div>
                }
              />
            </div>

            <textarea
              className="w-full resize-none h-[150px] border-[1px] border-black outline-none p-3 rounded-md"
              placeholder="Type Description"
              value={values.description}
              onChange={handleChange}
              onBlur={handleBlur}
              id="description"
            ></textarea>

            <ToggleSwitch
              label={"Active"}
              name={"isActive"}
              onChange={handleChange}
              value={values.isActive}
            />
          </div>
        }
      />
    </form>
  );
}

export default FormSharedEmployeeStatus;
