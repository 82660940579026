import React from "react";
import {
  Accordion,
  InputText,
  InputPassword,
} from "@bluesilodev/timhutcomponents";
import { ChevronSvg } from "components/icons";

function UserCredential({ handleChange, handleBlur, values, errors, touched }) {
  return (
    <div className=" w-full rounded-md  ">
      <Accordion
        title={"User Credential"}
        icons={[]}
        children={
          <div className="flex flex-col gap-3 w-full">
            <div className="w-full flex gap-3 pt-3">
              <InputText
                type={"email"}
                required={true}
                title={"Email Address"}
                className={"flex items-center gap-2 w-full"}
                label={null}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                id={"email"}
                error={
                  <div>{errors.email && touched.email && errors.email}</div>
                }
              />
              <InputText
                title={"Username"}
                required={true}
                className={"flex items-center gap-2 w-full"}
                label={null}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.userName}
                id={"userName"}
                error={
                  <div>
                    {errors.userName && touched.userName && errors.userName}
                  </div>
                }
              />
            </div>

            <div className="flex gap-3">
              <InputPassword
                title={"Password"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                id={"password"}
                error={
                  <div>
                    {errors.password && touched.password && errors.password}
                  </div>
                }
              />
              <InputPassword
                title={"Confirm Password"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.confirmPassword}
                id={"confirmPassword"}
                error={
                  <div>
                    {errors.confirmPassword &&
                      touched.confirmPassword &&
                      errors.confirmPassword}
                  </div>
                }
              />
            </div>
          </div>
        }
      />
    </div>
  );
}

export default UserCredential;
