import { useQuery, useMutation } from '@tanstack/react-query'
import { customAxios } from "utils/axios";

const API = `/api/user`;

export const useGetOrganization = (options = {}) => {

    const { location, department, ...optionOrganization } = options

    const fetchData = async () => {
        let queryParams = "";

        // Mengecek apakah parameter tidak kosong, jika kosong, tidak ditambahkan ke URL
        if (location) queryParams += `location=${location}&`;
        if (department) queryParams += `department=${department}&`;

        // Menghapus tanda '&' di akhir URL jika diperlukan
        if (queryParams.endsWith('&')) {
            queryParams = queryParams.slice(0, -1);
        }

        const url = queryParams ? `${API}/organization?${queryParams}` : `${API}/organization`;

        try {
            const res = await customAxios.get(url);

            return res.data;
        } catch (error) {
            console.error("Error fetching data: ", error); // Debug: Log error
            throw error;
        }
    };

    const queryKey = ["getOrganization"];
    if (location) queryKey.push(`location=${location}`);
    if (department) queryKey.push(`department=${department}`);

    return useQuery({
        queryKey: queryKey.length > 1 ? queryKey : ["getOrganization"],
        queryFn: fetchData,
        ...optionOrganization
    })
}

export const useGetFilterData = ({ onSuccess, onError }) => {
    return useQuery({
        queryKey: ["getAllfilter"],
        queryFn: async () => {
            const res = await customAxios.get(`${API}/filterAll`)
            return res.data;
        },
        onSuccess,
        onError
    })
}
