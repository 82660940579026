import * as Yup from 'yup';

const validationSchemaAddEmployee = Yup.object().shape({
    employeeID: Yup.string().required('Employee ID is required'),
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    userName: Yup.string().required('Username is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
    confirmPassword: Yup.string().oneOf([Yup.ref("password"), null]).required("Confirm Password is Required"),
    countryPhoneNumber: Yup.number().required('Country Number is required'),
    phoneNumber: Yup.number().required('Phone Number is required'),
    photo: Yup.mixed()
        .test("fileSize", "The file is too large", (value) => {
            if (value === undefined || value === "" || value.length === 0) return true;
            return value && value.size <= 2000000;
        })
        .test("type", "Only the following formats are accepted: .jpeg, .jpg .png", (value) => {

            if (value === undefined || value === "" || value.length === 0) return true;
            return value && (
                value.type === "image/jpeg" ||
                value.type === "image/png" ||
                value.type === "image/jpg"
            );
        }),
    gender: Yup.string().required("Gender is Required"),
    bloodTypes: Yup.string().required("Blood Type is Required"),
    religions: Yup.string().required("Religion is Required"),
    dateOfBirth: Yup.string().required('Date of Birth is required'),
    maritalStatus: Yup.string().required("Marital Status is Required"),
    address: Yup.string(),
    country: Yup.string(),
    postalCode: Yup.number(),
    role: Yup.array().of(Yup.string()).required('Job Level is required'),

    employeementDetail: Yup.object().shape({
        employeeStatus: Yup.string().required('Employee Status is required'),
        locations: Yup.string().required('Location is required'),

        joinDate: Yup.string().required('Join Date is required')
            .test("more than", "Start Date must be less than End Date", (val, context) => {
                const contextDate = context.parent.endDate;

                if (contextDate && val) {
                    const [startDay, startMonth, startYear] = val.split("/");
                    const joinDate = new Date(startYear, startMonth - 1, startDay)

                    const [endDay, endMonth, endYear] = contextDate.split("/")
                    const endDate = new Date(endYear, endMonth - 1, endDay)

                    return endDate > joinDate;
                }
                return true;
            }),

        endDate: Yup.string().required('End Date is required')
            .test(
                "is-greater",
                "End Date must be greater than Start Date",
                function (val, context) {
                    // 'this' refers to the entire object being validated
                    const contextDate = context.parent.joinDate;

                    if (contextDate && val) {
                        const [startDay, startMonth, startYear] = val.split("/");
                        const endDate = new Date(startYear, startMonth - 1, startDay)

                        const [endDay, endMonth, endYear] = contextDate.split("/")
                        const joinDate = new Date(endYear, endMonth - 1, endDay)

                        return endDate > joinDate;
                    }
                    return true;
                }
            ),

        departments: Yup.string().required('Departments is required'),
        // leaveDetail: Yup.object().shape({
        //     // leave: Yup.boolean(),
        //     leaveSettings: Yup.array().of(Yup.object().shape({
        //         leaveSettingID: Yup.string().required('Leave Setting ID is required'),
        //         defaultAmount: Yup.number().required('Default Amount is required'),
        //         gainPerMonth: Yup.number().required('Gain Per Month is required'),
        //         expiredCarryOver: Yup.number().required('Expired Carry Over is required'),
        //         maxCarryOver: Yup.number().required('Max Carry Over is required'),
        //     })),
        // }),
        jobPosition: Yup.string().required('Job Position is required'),
    }),

    hobbies: Yup.array()
        .of(Yup.string()),
    skills: Yup.array()
        .of(Yup.string()),
    languages: Yup.array()
        .of(Yup.string()),

    identificationDocument: Yup.object().shape({
        identificationType: Yup.string().required('Identification Type is required'),
        identityExpiredDate: Yup.string().required('Identity Expired Date is required'),
        nationality: Yup.string().required('Nationality is required'),
        identityNumber: Yup.number().required('Identity Number is required'),
        // isPermanentDate: Yup.boolean(),
        covid19VaksinationStatus: Yup.string(),
        attachments: Yup.mixed()
            .test("fileSize", "The file is too large", (value) => {
                if (value === undefined || value === "" || value.length === 0) return true;
                return value && value.size <= 2000000;
            })
            .test("type", "Only the following formats are accepted: .jpeg, .jpg .png", (value) => {

                if (value === undefined || value === "" || value.length === 0) return true;
                return value && (
                    value.type === "image/jpeg" ||
                    value.type === "image/png" ||
                    value.type === "image/jpg"
                );
            }),
    }),

    paymentDetail: Yup.object().shape({
        rateOfPays: Yup.string().required('Rate of Pay is required'),
        banks: Yup.string().required('Bank is required'),
        basicSalary: Yup.number().required('Basic Salary is required'),
        currencies: Yup.string().required('Currency for Salary is required'),
        numberAccount: Yup.number().required('Number Account is required'),
        // allowOvertime: Yup.boolean(),
        paymentMethods: Yup.string().required('Payment Method is required'),
        accountName: Yup.string().required('Account Name is required'),
        cpfAccountNumber: Yup.string().required('CPF Account Number is required'),
        monthlyAllowances: Yup.array().of(Yup.object().shape({
            typeOfAllowance: Yup.string().required("Type Of Allowance is Required"),
            amount: Yup.number().required("Amount is Required"),
        })),
    }),
});

export default validationSchemaAddEmployee;


