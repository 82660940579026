import React from "react";
import PropTypes from "prop-types";

function ChevronSvg({ color, className, ...props }) {
  return (
    <svg
      className={`${className}`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6 9L12 15L18 9"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

ChevronSvg.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

ChevronSvg.defaultProps = {
  color: "black",
  className: "w-[20px] h-[20px] ",
};

export default ChevronSvg;
