import React from 'react'

function LengthOf() {
  return (
    <div>
      LENGTH OF
    </div>
  )
}

export default LengthOf
