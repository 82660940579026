import React from "react";
import { FieldArray, useFormikContext } from "formik";
import { Accordion, InputText, Button } from "@bluesilodev/timhutcomponents";
import SelectChoice from "components/SelectChoice";

import { DeleteSvg, FiPlusCircle } from "components/icons";

function DepartmentArr() {
  const { values, setFieldValue, errors, touched, setFieldTouched } =
    useFormikContext();

  const handleKeyPress = (e, departmentIndex) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const newJobPosition = e.target.value.trim();
      if (newJobPosition) {
        const currentJobPositions =
          values.departments[departmentIndex].jobPosition;
        const jobPositions = [...currentJobPositions, newJobPosition];

        setFieldValue(
          `departments.${departmentIndex}.jobPosition`,
          jobPositions
        );
        e.target.value = "";
      }
    }
  };

  const handleDelete = (index, departmentIndex) => {
    const currentJobPositions = values.departments[departmentIndex].jobPosition;

    const filterData = currentJobPositions.filter((_, idx) => idx !== index);

    setFieldValue(`departments.${departmentIndex}.jobPosition`, filterData);
  };

  return (
    <div className="py-5">
      <Accordion
        title={"Departments"}
        icons={[]}
        children={
          <FieldArray name="departments">
            {({ push, remove }) => (
              <div>
                {values.departments.map((depart, departmentIndex) => (
                  <div
                    key={departmentIndex}
                    className="flex flex-col gap-3 pt-5 w-full"
                  >
                    <div className="w-full flex gap-3">
                      <div className="flex gap-3 w-[50%]">
                        <InputText
                          type={"text"}
                          title={"Department"}
                          label={null}
                          id={`departments.${departmentIndex}.department`}
                          value={depart.department}
                          onChange={(e) => {
                            setFieldValue(
                              `departments.${departmentIndex}.department`,
                              e.target.value
                            );
                          }}
                          onBlur={() =>
                            setFieldTouched(
                              `departments.${departmentIndex}.department`,
                              true
                            )
                          }
                          error={
                            errors.departments &&
                            errors.departments[departmentIndex] &&
                            errors.departments[departmentIndex].department && // Periksa apakah ada error pada properti department
                            touched.departments &&
                            touched.departments[departmentIndex] &&
                            touched.departments[departmentIndex].department && ( // Periksa apakah ada sentuhan pada properti department
                              <div className="text-red-500">
                                {errors.departments[departmentIndex].department}
                              </div>
                            )
                          }
                        />
                      </div>

                      <div>
                        {departmentIndex > 0 && (
                          <button
                            className="border-[1px] border-black rounded-full w-[60px] h-[60px] flex justify-center items-center hover:bg-gray-300"
                            type="button"
                            onClick={() => remove(departmentIndex)}
                          >
                            <DeleteSvg />
                          </button>
                        )}
                      </div>
                    </div>

                    <div className="w-full flex gap-3 items-center">
                      <div className="w-[50%] min-w-[50%]">
                        <InputText
                          label={null}
                          title={"Job Position"}
                          onKeyPress={(e) => handleKeyPress(e, departmentIndex)}
                          onBlur={() =>
                            setFieldTouched(
                              `departments.${departmentIndex}.jobPosition`,
                              true
                            )
                          }
                          error={
                            errors.departments &&
                            errors.departments[departmentIndex] &&
                            errors.departments[departmentIndex].jobPosition &&
                            touched.departments &&
                            touched.departments[departmentIndex] &&
                            touched.departments[departmentIndex]
                              .jobPosition && (
                              <div className="text-red-500">
                                {
                                  errors.departments[departmentIndex]
                                    .jobPosition
                                }
                              </div>
                            )
                          }
                        />
                      </div>

                      <div className="flex flex-wrap gap-3">
                        {depart.jobPosition.map((job, jobIndex) => (
                          <SelectChoice
                            key={jobIndex}
                            title={job}
                            handleDelete={() =>
                              handleDelete(jobIndex, departmentIndex)
                            }
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                ))}

                <div className="pt-5 w-full">
                  <Button
                    type={"button"}
                    onClick={() => push({ department: "", jobPosition: [] })}
                    className={"group w-[50%]"}
                    label={
                      <div className="flex gap-2 items-center ">
                        <FiPlusCircle
                          color={"orange-500"}
                          className={"group-hover:text-white w-[20px] h-[20px]"}
                        />
                        <h1>Add More Departments</h1>
                      </div>
                    }
                  />
                </div>
              </div>
            )}
          </FieldArray>
        }
      />
    </div>
  );
}

export default DepartmentArr;
