import React from "react";
import PropTypes from "prop-types";

function FiGrid({ color, className, ...props }) {
  return (
    <svg
      className={`${className} text-${color} stroke-current`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21 14H14V21H21V14Z"
        // stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 14H3V21H10V14Z"
        // stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 3H14V10H21V3Z"
        // stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 3H3V10H10V3Z"
        // stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

FiGrid.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

FiGrid.defaultProps = {
  color: "black",
  className: "w-[20px] h-[20px]",
};

export default FiGrid;
