import React from "react";
import PropTypes from "prop-types";

function FiHeart({ className, color, ...props }) {
  return (
    <svg
      {...props}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className} transition-all duration-300`}
    >
      <path
        d="M13.8933 3.57455C13.5528 3.23389 13.1485 2.96365 12.7036 2.77927C12.2586 2.5949 11.7817 2.5 11.3 2.5C10.8183 2.5 10.3414 2.5949 9.89643 2.77927C9.45146 2.96365 9.04717 3.23389 8.70667 3.57455L8 4.28122L7.29333 3.57455C6.60554 2.88676 5.67269 2.50036 4.7 2.50036C3.72731 2.50036 2.79446 2.88676 2.10666 3.57455C1.41887 4.26235 1.03247 5.19519 1.03247 6.16788C1.03247 7.14057 1.41887 8.07342 2.10666 8.76122L2.81333 9.46788L8 14.6545L13.1867 9.46788L13.8933 8.76122C14.234 8.42071 14.5042 8.01643 14.6886 7.57145C14.873 7.12648 14.9679 6.64954 14.9679 6.16788C14.9679 5.68623 14.873 5.20929 14.6886 4.76431C14.5042 4.31934 14.234 3.91505 13.8933 3.57455V3.57455Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

FiHeart.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

FiHeart.defaultProps = {
  color: "black",
  className: "w-[20px] h-[20px]",
};

export default FiHeart;
