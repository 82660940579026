import React from "react";
import {
  Accordion,
  Button,
  InputSelect,
  InputText,
} from "@bluesilodev/timhutcomponents";
import { FieldArray } from "formik";

import {
  FiPlusCircle,
  DeleteSvg,
} from "../../../../components/icons";

function MonthlyAllowance({
  values,
  errors,
  touched,
  setFieldTouched,
  setFieldValue,
}) {
  return (
    <div className="py-5">
      <Accordion
        title={"Montly Allowance"}
        icons={[]}
        children={
          <FieldArray name="paymentDetail.monthlyAllowances">
            {({ push, remove }) => (
              <div className="w-full flex flex-col gap-2 pt-3">
                {values.paymentDetail.monthlyAllowances.map((item, index) => (
                  <div key={index} className="flex gap-2 w-full">
                    <div className="w-[60%] flex gap-3">
                      <InputSelect
                        title={"Type of Allowance"}
                        required={true}
                        options={[
                          { label: "Transportaion Fee", value: "Transportaion Fee" },
                          { label: "Mobile Fee", value: "Mobile Fee" },
                          { label: "Food Fee", value: "Food Fee" },
                        ]}
                        id={`paymentDetail.monthlyAllowances.${index}.typeOfAllowance`}
                        value={
                          values.paymentDetail.monthlyAllowances[index]
                            .typeOfAllowance
                        }
                        onChange={(e) => {
                          setFieldValue(
                            `paymentDetail.monthlyAllowances.${index}.typeOfAllowance`,
                            e.target.value
                          );
                        }}
                        onBlur={() =>
                          setFieldTouched(
                            `paymentDetail.monthlyAllowances.${index}.typeOfAllowance`,
                            true
                          )
                        }
                        error={
                          errors.paymentDetail &&
                          errors.paymentDetail.monthlyAllowances &&
                          errors.paymentDetail.monthlyAllowances[index] &&
                          errors.paymentDetail.monthlyAllowances[index]
                            .typeOfAllowance &&
                          touched.paymentDetail &&
                          touched.paymentDetail.monthlyAllowances &&
                          touched.paymentDetail.monthlyAllowances[index] &&
                          touched.paymentDetail.monthlyAllowances[index]
                            .typeOfAllowance && (
                            <div className="text-red-500">
                              {
                                errors.paymentDetail.monthlyAllowances[index]
                                  .typeOfAllowance
                              }
                            </div>
                          )
                        }
                      />
                      <InputText
                        type={"number"}
                        label={null}
                        title={"Amount"}
                        id={`paymentDetail.monthlyAllowances.${index}.amount`}
                        value={
                          values.paymentDetail.monthlyAllowances[index].amount
                        }
                        onChange={(e) => {
                          setFieldValue(
                            `paymentDetail.monthlyAllowances.${index}.amount`,
                            e.target.value
                          );
                        }}
                        onBlur={() =>
                          setFieldTouched(
                            `paymentDetail.monthlyAllowances.${index}.amount`,
                            true
                          )
                        }
                        error={
                          errors.paymentDetail &&
                          errors.paymentDetail.monthlyAllowances &&
                          errors.paymentDetail.monthlyAllowances[index] &&
                          errors.paymentDetail.monthlyAllowances[index]
                            .amount &&
                          touched.paymentDetail &&
                          touched.paymentDetail.monthlyAllowances &&
                          touched.paymentDetail.monthlyAllowances[index] &&
                          touched.paymentDetail.monthlyAllowances[index]
                            .amount && (
                            <div className="text-red-500">
                              {
                                errors.paymentDetail.monthlyAllowances[index]
                                  .amount
                              }
                            </div>
                          )
                        }
                      />
                    </div>
                    <div
                      onClick={() => remove(index)}
                      className={`rounded-full cursor-pointer border-[1px] border-black h-[60px] w-[60px] flex justify-center items-center hover:bg-slate-300 ${
                        index > -1 ? "block" : "hidden"
                      }`}
                    >
                      <DeleteSvg />
                    </div>
                  </div>
                ))}
                <Button
                  type={"button"}
                  onClick={() => push({ typeOfAllowance: "", amount: "" })}
                  label={
                    <div className="flex gap-2">
                      <FiPlusCircle color={"#A3A3AB"} />
                      <div className="text-white">Add Allowance</div>
                    </div>
                  }
                  // backgroundColor={"#F1F1F1"}
                  className={"w-[60%] mt-2 btn-secondary-solid"}
                  style="solid"
                />
              </div>
            )}
          </FieldArray>
        }
      />
    </div>
  );
}

export default MonthlyAllowance;
