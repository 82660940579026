import React from "react";

function DiamondSvg() {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0001 0.5C16.1647 0.499975 16.3268 0.540578 16.4719 0.618205C16.617 0.695832 16.7408 0.808084 16.8321 0.945L16.8941 1.053L19.8941 7.053C19.9738 7.21209 20.0096 7.38956 19.9978 7.5671C19.9861 7.74465 19.9271 7.91583 19.8271 8.063L19.7431 8.169L10.7701 18.142C10.6642 18.2707 10.5273 18.3704 10.3724 18.4318C10.2174 18.4932 10.0494 18.5142 9.88412 18.493L9.77112 18.474C9.56342 18.4275 9.3766 18.3145 9.23912 18.152L0.257117 8.17C0.138062 8.03788 0.0563959 7.8764 0.0205373 7.7022C-0.0153212 7.52801 -0.00407484 7.3474 0.0531172 7.179L0.106117 7.054L3.10612 1.054C3.17971 0.906815 3.28849 0.780067 3.42281 0.684997C3.55713 0.589927 3.71284 0.529474 3.87612 0.509L4.00012 0.5H16.0001ZM12.5761 8.5H7.42312L10.0001 14.842L12.5761 8.5ZM16.7531 8.5H14.7351L13.0741 12.588L16.7531 8.5ZM5.26412 8.5H3.24612L6.92412 12.587L5.26412 8.5ZM6.62212 2.5H4.61812L2.61812 6.5H5.32212L6.62212 2.5ZM11.2731 2.5H8.72612L7.42712 6.5H12.5721L11.2731 2.5ZM15.3811 2.5H13.3771L14.6771 6.5H17.3811L15.3811 2.5Z"
        fill="url(#paint0_linear_159_17006)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_159_17006"
          x1="10"
          y1="0.5"
          x2="10"
          y2="18.501"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8812A" />
          <stop offset="1" stop-color="#F56E22" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default DiamondSvg;
