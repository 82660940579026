import React, { useState, useEffect } from "react";
import { ModalDialog } from "@bluesilodev/timhutcomponents";
import { useQueryClient } from "@tanstack/react-query";
import { useGetComunity } from "service/comunityApi";
import { useDispatch } from "react-redux";
import { setDataPostExcel } from "store/comunitySlice";

import PersonComp from "./PersonComp";
import ChildModal from "components/ChildModal";

function Nationality() {
  const [itemModal, setItemModal] = useState({});
  const [showModal, setShowModal] = useState(false);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { data, isPending } = useGetComunity(
    { nationality: true },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getComunity");
      },
      onError: (err) => {
        console.log("err: ", err);
      },
    }
  );

  // SET DATA TO REDUX FOR DOWLOAD EXCEL
  useEffect(() => {
    if (data && data.data) {

      const natinalData = data.data.map((item, _) => {
        return {
          nationality: item.nationality,
          employee: item.data[0].firstName + " " + item.data[0].lastName,
        };
      });

      // SET DATA TO REDUX FOR DOWLOAD EXCEL
      dispatch(setDataPostExcel(natinalData));
    }
  }, [data, dispatch]);

  if (isPending) {
    return <div>Loading...</div>;
  }

  const handleShowModal = (item) => {
    setShowModal(!showModal);
    setItemModal(item);
  };

  return (
    <div className="flex flex-col gap-5 pl-5 pb-5 w-full">
      {data?.data.map((item, index) => {
        return (
          <PersonComp
            key={index}
            dataMap={item.data}
            length={item.length}
            title={item.nationality}
            handleShowModal={handleShowModal}
          />
        );
      })}

      {/* MODAL */}
      {showModal && (
        <ModalDialog
          className={"w-[700px]"}
          show={showModal}
          title={"Employee Details"}
          onClose={handleShowModal}
        >
          <ChildModal
            title={"Nationality"}
            subtitle={itemModal.nationality}
            department={itemModal.department}
            email={itemModal.email}
            firstName={itemModal.firstName}
            lastName={itemModal.lastName}
            jobPosition={itemModal.jobPosition}
            location={itemModal.location}
            phoneNumber={itemModal.phoneNumber}
            photo={itemModal.photo}
          />
        </ModalDialog>
      )}
    </div>
  );
}

export default Nationality;
