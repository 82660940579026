import * as yup from 'yup'

export const cardValidationSchema = yup.object().shape({

    firstName: yup.string().required("FirtsName is Required"),
    lastName: yup.string().required("LastName is Required"),
    employeementDetail: yup.object().shape({
        locations: yup.string().required("Location is Required"),
        departments: yup.string().required("Department is Required"),
        jobPosition: yup.string().required("Job Position is Required"),
    })
})