import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function NavComunity() {
  const location = useLocation();
  const navigate = useNavigate();
  // THIS IS STATE FROM COMUNITY REDUX
  const option = useSelector((state) => state.comunitySlice.option);

  const handleNavLink = (path) => {
    navigate(path);
  };

  const paths = [
    "/comunity/nationality",
    "/comunity/gender",
    "/comunity/age",
    "/comunity/marital",
    "/comunity/religion",
    "/comunity/department",
    "/comunity/hobbies",
    "/comunity/languages",
    "/comunity/salary",
    "/comunity/lengthOf",
  ];

  return (
    <div className="flex gap-6 w-screen h-full items-center select-none z-0 ">
      {option.map((item, index) => {
        const path = paths[index];
        const isActive = location.pathname === path;

        return (
          <div
            key={index}
            onClick={() => handleNavLink(path)}
            className={`${
              isActive && "text-orange-500"
            } flex items-center hover:text-orange-500 hover:cursor-pointer ml-14 h-[50px]  `}
          >
            <div className="w-[110px] ">{item}</div>
          </div>
        );
      })}
    </div>
  );
}

export default NavComunity;
