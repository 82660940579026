import React, { useLayoutEffect, useRef, useState } from "react";
import { SearchBox, Button, DataTable } from "@bluesilodev/timhutcomponents";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { useGetAllLocation } from "service/locationAPI";
import { useGetAllCountry } from "service/commonAPI";

import { LocationSvg } from "components/icons";
import TableMenu from "./TableMenu";
import { adminRole } from "utils/roleFromLocalStorage";

function Locations() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const inputRef = useRef();
  const [keyword, setKeyword] = useState("");

  const { data, isSuccess, refetch } = useGetAllLocation(
    {
      keyword,
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getLocation");
      },
      onError: (err) => console.log("ERR: ", err),
    }
  );

  // FETCH DATA IF SUCCES GET API
  if (isSuccess && data?.data.length !== 0) {
    refetch();
  }

  // CHANGE FOR FOCUS INPUT
  useLayoutEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [keyword]);

  const { data: resCountry, isPending } = useGetAllCountry({
    onSuccess: () => {
      queryClient.invalidateQueries("getCountry");
    },
  });

  if (isPending) {
    return <div>Loading...</div>;
  }

  const countryData = resCountry?.data.map((country, _) => {
    return {
      label: country.name,
      value: country.country_code,
    };
  });

  // COLUMN
  const dataColumn = [
    {
      id: "locationName",
      accessorFn: (row) => row?.locationName,
      header: () => <span>Location Name</span>,
      enableSorting: true,
    },
    {
      id: "country",
      accessorFn: (row) => {
        return row.country;
      },
      // cell: (param) => {
      //   const data = param.getValue();

      //   // const hasilData = data.map((item, index) => {
      //   //   return item.country;
      //   // });

      //   console.log("DATA: ", data);

      //   const country = countryData.filter((item, _) => {
      //     // if(item.value === data?.country){
      //     //   return
      //     // }
      //     return item.value;
      //   });

      //   // console.log("COUNTRY : ", country)

      //   return (
      //     <div>
      //       {countryData.filter((item, _) => {
      //         // console.log("ITEM : ", item.value === data?.country);
      //         // return item.value === data.country;
      //       })}
      //     </div>
      //   );
      // },
      header: () => <span>Country</span>,
      enableSorting: true,
    },
    {
      id: "postalCode",
      accessorFn: (row) => row?.postalCode,
      header: () => <span>Postal Code</span>,
      enableSorting: true,
    },
    {
      id: "address",
      accessorFn: (row) => row?.address,
      header: () => <span>Location Address</span>,
      enableSorting: true,
    },
    {
      id: "phoneNumber",
      accessorFn: (row) => row?.phoneNumber,
      header: () => <span>Phone Number</span>,
      enableSorting: true,
    },
    {
      id: "departments",
      accessorFn: (row) => {
        const mapingData = row?.departments.map(
          (item, index) => item?.department
        );
        return mapingData;
      },
      header: () => <span>Departments</span>,
      enableSorting: true,
    },
    {
      id: "job_position",
      accessorFn: (row) => {
        return row?.departments[0]?.jobPosition;
      },
      header: () => <span>Job Positions</span>,
      enableSorting: true,
    },
    {
      id: "action",
      disableSorting: true,
      header: () => "",
      accessorFn: (row) => {
        return row;
      },
      cell: (param) => {
        const data = param.getValue();

        return (
          <div className="cursor-pointer w-fit">
            <TableMenu data={data} />
          </div>
        );
      },
    },
  ];

  return (
    <div className="w-full h-full overflow-y-auto pt-10">
      <div className="w-full flex justify-between">
        <SearchBox
          className={"w-[300px]"}
          value={keyword}
          ref={inputRef}
          onChange={(e) => setKeyword(e.target.value)}
          placeholder="Search by location name"
        />
        {adminRole && (
          <Button
            style={"solid"}
            onClick={() => navigate("/createLocation")}
            className={"w-[200px]"}
            label={
              <div className="flex gap-2">
                <LocationSvg color={"white"} />
                <div>Create New Location</div>
              </div>
            }
          />
        )}
      </div>

      <div className="py-10">
        <DataTable
          title="Attendance Data"
          columns={dataColumn}
          data={!data?.data ? <h1>Data Not Found</h1> : data?.data}
          pagination={true}
        />
      </div>
    </div>
  );
}

export default Locations;
