import React from "react";
import PropTypes from "prop-types";

function FiUser({ color, className, ...props }) {
  return (
    <svg
      {...props}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`transition-all duration-300 ${className}`}
    >
      <g clipPath="url(#clip0_141_33417)">
        <path
          d="M15.3333 14.4993V13.1659C15.3328 12.5751 15.1362 12.0011 14.7742 11.5341C14.4122 11.0672 13.9053 10.7336 13.3333 10.5859"
          fill="#1A1A2E"
        />
        <path
          d="M15.3333 14.4993V13.1659C15.3328 12.5751 15.1362 12.0011 14.7742 11.5341C14.4122 11.0672 13.9053 10.7336 13.3333 10.5859"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.3334 14.5V13.1667C11.3334 12.4594 11.0525 11.7811 10.5524 11.281C10.0523 10.781 9.37399 10.5 8.66675 10.5H3.33341C2.62617 10.5 1.94789 10.781 1.4478 11.281C0.9477 11.7811 0.666748 12.4594 0.666748 13.1667V14.5"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.6667 2.58594C11.2404 2.7328 11.7488 3.0664 12.1118 3.53414C12.4749 4.00188 12.672 4.57716 12.672 5.16927C12.672 5.76138 12.4749 6.33666 12.1118 6.8044C11.7488 7.27214 11.2404 7.60574 10.6667 7.7526"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.99992 7.83333C7.47268 7.83333 8.66659 6.63943 8.66659 5.16667C8.66659 3.69391 7.47268 2.5 5.99992 2.5C4.52716 2.5 3.33325 3.69391 3.33325 5.16667C3.33325 6.63943 4.52716 7.83333 5.99992 7.83333Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_141_33417">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

FiUser.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

FiUser.defaultProps = {
  color : "black", 
  className : "w-[20px] h-[20px]"
};

export default FiUser;
