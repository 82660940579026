import React from "react";
import PropTypes from "prop-types";

function UserIcon({ className, color, ...props }) {
  return (
    <svg
      {...props}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className} transition-all duration-300 `}
    >
      <path
        d="M19.7274 20.4471C19.2716 19.1713 18.2672 18.0439 16.8701 17.2399C15.4729 16.4358 13.7611 16 12 16C10.2389 16 8.52706 16.4358 7.12991 17.2399C5.73276 18.0439 4.72839 19.1713 4.27259 20.4471"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle
        cx="12"
        cy="8"
        r="4"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}

UserIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

UserIcon.defaultProps = {
  color: "black",
  className: "w-[20px] h-[20px]",
};

export default UserIcon;
