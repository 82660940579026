import { createSlice } from '@reduxjs/toolkit'

const forgotPasswordSlice = createSlice({
    name: "forgotValue",
    initialState: {
        value: null
    },
    reducers: {
        setForgotValue(state, action) {
            state.value = action.payload
        }
    }
})

export const { setForgotValue } = forgotPasswordSlice.actions;
export default forgotPasswordSlice.reducer;