import React from "react";
import { InputText, InputDate, Button } from "@bluesilodev/timhutcomponents";
import { FiSaveSvg, DeleteSvg } from "components/icons";
import { useFormik } from "formik";
import { formatCalendarDate } from "configures/formatDate";

function AddOrEditForm({
  formId,
  initialValues,
  removeForm,
  getValue,
  title,
  formikVal,
  index,
  isEdit,
  validationSchema,
  id,
}) {
  const formik = useFormik({
    initialValues: {
      school: isEdit ? formikVal[index].school : initialValues.school,
      degree: isEdit ? formikVal[index].degree : initialValues.degree,
      fieldOfStudy: isEdit
        ? formikVal[index].fieldOfStudy
        : initialValues.fieldOfStudy,
      grade: isEdit ? formikVal[index].grade : initialValues.grade,
      startDate: isEdit
        ? formatCalendarDate(formikVal[index].startDate)
        : initialValues.startDate,
      endDate: isEdit
        ? formatCalendarDate(formikVal[index].endDate)
        : initialValues.endDate,
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const [dd, mm, yy] = values.startDate.split("/");
      const [ddEnd, mmEnd, yyEnd] = values.endDate.split("/");
      const generateStartDate = new Date(yy, mm - 1, dd);
      const generateEndDate = new Date(yyEnd, mmEnd - 1, ddEnd);

      const allValues = {
        ...values,
        startDate: generateStartDate,
        endDate: generateEndDate,
      };

      const updatedForm = { ...formikVal[index], ...allValues };

      getValue(updatedForm, index);
      setSubmitting(false);
      if (isEdit) {
        removeForm(index);
      } else {
        removeForm(id);
      }

      resetForm();
    },
  });

  return (
    <div className="flex flex-col ">
      <h1 className="font-semibold">{title}</h1>
      <form
        autoComplete="off"
        onSubmit={formik.handleSubmit}
        className="flex flex-col gap-3 w-full pt-2"
      >
        <div className="flex gap-3">
          <InputText
            title="School"
            label={null}
            id={`school-${formId}`}
            name="school"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.school}
            error={
              formik.touched.school && formik.errors.school ? (
                <div className="text-red-500">{formik.errors.school}</div>
              ) : null
            }
          />

          <InputText
            title="Degree"
            label={null}
            id={`degree-${formId}`}
            name="degree"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.degree}
            error={
              formik.touched.degree && formik.errors.degree ? (
                <div className="text-red-500">{formik.errors.degree}</div>
              ) : null
            }
          />

          <InputText
            title="Field of Study"
            label={null}
            id={`fieldOfStudy-${formId}`}
            name="fieldOfStudy"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.fieldOfStudy}
            error={
              formik.touched.fieldOfStudy && formik.errors.fieldOfStudy ? (
                <div className="text-red-500">{formik.errors.fieldOfStudy}</div>
              ) : null
            }
          />
        </div>

        <div className="flex gap-3">
          <InputText
            title="Field of Study"
            label={null}
            id={`grade-${formId}`}
            name="grade"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.grade}
            error={
              formik.touched.grade && formik.errors.grade ? (
                <div className="text-red-500">{formik.errors.grade}</div>
              ) : null
            }
          />

          <InputDate
            label={"Start Date"}
            name="startDate"
            setFieldValue={formik.setFieldValue}
            value={formik.values.startDate}
            errors={
              formik.touched.startDate && formik.errors.startDate ? (
                <div className="text-red-500">{formik.errors.startDate}</div>
              ) : null
            }
          />
          <InputDate
            label={"End Date"}
            name="endDate"
            setFieldValue={formik.setFieldValue}
            value={formik.values.endDate}
            errors={
              formik.touched.endDate && formik.errors.endDate ? (
                <div className="text-red-500">{formik.errors.endDate}</div>
              ) : null
            }
          />
        </div>

        <div className="flex gap-3 w-full justify-end mt-3">
          <Button
            type="button"
            label={
              <div className="flex gap-2">
                <DeleteSvg color={"white"} />
                <h1 className="text-white">Delete</h1>
              </div>
            }
            style="solid"
            className="w-[200px] border-[4px] border-black"
            onClick={() => removeForm(formId)}
          />
          <Button
            type="submit"
            label={
              <div className="flex gap-2">
                <FiSaveSvg />
                <h1 className="text-white">Save</h1>
              </div>
            }
            style="solid"
            className="w-[200px] border-[4px] border-black"
          />
        </div>
      </form>
    </div>
  );
}

export default AddOrEditForm;
