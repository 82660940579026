import { useQuery, useMutation } from '@tanstack/react-query'
import { customAxios } from "utils/axios";

const API = `/api/userInformation`;

export const useGetUserInform = ({ onError, onSuccess }) => {
    return useQuery({
        queryKey: ["userInform"],
        queryFn: async () => {
            const res = await customAxios.get(`${API}/`)
            return res.data;
        },
        onError,
        onSuccess
    },)
}

export const usePostUserInform = ({ onError, onSuccess }) => {
    return useMutation({
        mutationFn: async (body) => {

            const res = await customAxios.post(`${API}/`, body)

            console.log("RES: ", res.data)
            return res.data;
        },
        onError,
        onSuccess
    })
}

export const useUpdateUserInform = (uId, { onError, onSuccess }) => {
    return useMutation({
        mutationFn: async (body) => {

            const res = await customAxios.put(`${API}/${uId}`, body)

            return res.data;
        },

        onError,
        onSuccess
    })
}

export const useUpdateUserInformOrganization = (uId, { onError, onSuccess }) => {
    return useMutation({
        mutationFn: async (body) => {

            const res = await customAxios.put(`${API}/organization/${uId}`, body)

            return res.data;
        },

        onError,
        onSuccess
    })
}