import React, { useState } from "react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import FormLeave from "./formLeave";

function ActionLeave({ data }) {
  const [handleModal, setHandleModal] = useState(false);

  const handleClickModal = () => {
    setHandleModal(!handleModal);
  };

  return (
    <div>
      <div className="cursor-pointer w-fit" onClick={handleClickModal}>
        <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
      </div>

      {handleModal && (
        <FormLeave
          showModal={handleModal}
          setShowModal={setHandleModal}
          data={data}
        />
      )}
    </div>
  );
}

export default ActionLeave;
