import React from "react";
import PropTypes from "prop-types";

function FiHome({ className, color, ...props }) {
  return (
    <svg
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
    >
      <path
        d="M2 6.50065L8 1.83398L14 6.50065V13.834C14 14.1876 13.8595 14.5267 13.6095 14.7768C13.3594 15.0268 13.0203 15.1673 12.6667 15.1673H3.33333C2.97971 15.1673 2.64057 15.0268 2.39052 14.7768C2.14048 14.5267 2 14.1876 2 13.834V6.50065Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 15.1667V8.5H10V15.1667"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

FiHome.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

FiHome.defaultProps = {
  color: "black",
  className: "w-[20px] h-[20px]",
};

export default FiHome;
