import { useQuery, useMutation } from '@tanstack/react-query'
import { customAxios } from "utils/axios";
import { getLocalStorage } from "utils/common";

const API = `/api/company`;

const { companyID } = getLocalStorage();

export const useGetAllCompany = ({ onError, onSuccess }) => {
    return useQuery({
        queryKey: ["getCompany"],
        queryFn: async () => {
            const res = await customAxios.get(`${API}/`);
            return res.data.data[0];
        },
        onError,
        onSuccess
    });
}

export const useGetCompanyById = ({ onSuccess, onError }) => {
    return useQuery({
        queryKey: ["getCompanyById"],
        queryFn: async () => {
            const res = await customAxios.get(`${API}/${companyID}/`)

            return res.data;
        },
        onSuccess,
        onError
    })
}

export const useCreateCompany = ({ onSuccess }) => {
    return useMutation({
        mutationFn: async (body) => {
            const res = await customAxios.post(`${API}`, body)

            return res.data;
        },
        onSuccess,
    });
};

export const useDeleteCompany = ({ onSuccess }) => {
    return useMutation({
        mutationFn: async () => {
            const res = await customAxios.delete(`${API}/${companyID}`)

            return res;
        },
        onSuccess,
    });
};

export const useUpdateCompany = ({ onSuccess, onError }) => {
    return useMutation({
        mutationFn: async (body) => {

            const res = await customAxios.put(`${API}/${companyID}`, body)

            return res.data;
        },
        onSuccess,
        onError
    });
};


