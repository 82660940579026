import React from "react";
import {
  Accordion,
  InputSelect,
  InputText,
  TextArea,
} from "@bluesilodev/timhutcomponents";
import { useGetAllCountry } from "service/commonAPI";
import { QueryClient } from "@tanstack/react-query";

function Address({
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
  setFieldValue,
}) {
  const queryClient = new QueryClient();

  const { data: resCountry, isPending } = useGetAllCountry({
    onSuccess: () => {
      queryClient.invalidateQueries("getCountry");
    },
  });

  if (isPending) {
    return <h1>Loading...</h1>;
  }

  const countryList = resCountry.data.map((item) => {
    return {
      label: item.name,
      value: item.name,
    };
  });

  return (
    <div>
      <Accordion
        title={"Address"}
        icons={[]}
        children={
          <div className="w-[65%] pt-3 flex flex-col gap-3">
            <div className="flex gap-3">
              <InputSelect
                title={"Country"}
                options={countryList}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.country}
                id={"country"}
                error={
                  <div>
                    {errors.country && touched.country && errors.country}
                  </div>
                }
              />
              <InputText
                type={"number"}
                label={null}
                title={"Postal Code"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.postalCode}
                id={"postalCode"}
                error={
                  <div>
                    {errors.postalCode &&
                      touched.postalCode &&
                      errors.postalCode}
                  </div>
                }
              />
            </div>

            {/* <TextArea
              label={"Address"}
              onChange={handleChange}
              // onBlur={handleBlur}
              value={values.address}
              id={"address"}
              // error={
              //   <div>
              //     {errors.address && touched.address && errors.address}
              //   </div>
              // }
            /> */}
            <textarea
              id="address"
              className="w-full border-[1px] border-black resize-none h-[150px] p-3 rounded-md outline-none"
              placeholder="Address"
              value={values.address}
              onChange={handleChange}
              onBlur={handleBlur}
            ></textarea>
            {errors.address && touched.address && (
              <div className="text-red-500"> {errors.address} </div>
            )}
          </div>
        }
      />
    </div>
  );
}

export default Address;
