import { createSlice } from "@reduxjs/toolkit";

// SLICE REDUCER
const userInformSlice = createSlice({
    name: "userInformSlice",
    initialState: {
        userInform: "",
        userInformationID: ""
    },
    reducers: {
        setUserData(state, action) {
            state.userInform = action.payload
            console.log('ORGANISAIS ID : ', state.userInform)
        },

        setUserInformationID(state, action) {
            state.userInformationID = action.payload
            console.log('ORGANISAIS ID : ', state.userInformationID)
        }
    }
});


export const { setUserData, setUserInformationID } = userInformSlice.actions;
export default userInformSlice.reducer;
