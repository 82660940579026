import React, { useEffect } from "react";
import AppRouter from "routes";
import { BrowserRouter as Router } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import AlertMessage from 'components/alertMessage';
import { setLoginReducer } from "store/userLoginSlice";

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // first time set reducer if got localstorage
    const localStorageCurrentUser = JSON.parse(localStorage.getItem("currentUser"));
    const localStorageAccessToken = localStorage.getItem("accessToken");
    if (localStorageCurrentUser && localStorageAccessToken && localStorageCurrentUser !== "null" && localStorageAccessToken !== "null") {
      dispatch(setLoginReducer({
        currentUser: localStorageCurrentUser,
        token: localStorageAccessToken,
      }));
    } else {
      if (window.__POWERED_BY_QIANKUN__) {
        window.location.href = "/"
      }
    }
  }, []);

  return (
    <React.Fragment>
      <Router basename={window.__POWERED_BY_QIANKUN__ ? '/employee' : '/'}>
        <AppRouter />
      </Router>
      <AlertMessage />
    </React.Fragment>
  );
}

export default App;
