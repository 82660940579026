import { useQuery, useMutation } from '@tanstack/react-query'
import { customAxios } from "utils/axios";

const API = `/api/event`;

export const useGetAllEvent = (keyword, option) => {
    return useQuery({
        queryKey: ["getEvent", keyword],
        queryFn: async () => {
            const res = await customAxios.get(`${API}?keyword=${keyword}`);
            return res.data;
        },
        ...option
    });
}

export const useGetEventById = (uId, { onSuccess, onError }) => {
    return useQuery({
        queryKey: ["getEventById", uId],
        queryFn: async () => {
            const res = await customAxios.get(`${API}/${uId}/`)

            return res.data;
        },
        onSuccess,
        onError
    })
}

export const useCreateEvent = ({ onSuccess, onError }) => {
    return useMutation({
        mutationFn: async (body) => {
            const res = await customAxios.post(`${API}`, body)

            return res.data;
        },
        onSuccess,
        onError
    });
};

export const useDeleteEvent = (uId, { onSuccess, onError }) => {
    return useMutation({
        mutationFn: async () => {
            const res = await customAxios.delete(`${API}/${uId}`)

            return res;
        },
        onSuccess,
        onError
    });
};

export const useUpdateEvent = (uId, { onSuccess, onError }) => {
    return useMutation({
        mutationFn: async (body) => {

            const res = await customAxios.put(`${API}/${uId}`, body)

            return res.data;
        },
        onSuccess,
        onError
    });
};
