import React from "react";
import PropTypes from "prop-types";

function LocationSvg({ color, className, ...props }) {
  return (
    <svg
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.66659 7.50099C9.66659 6.58014 8.92043 5.83398 8.00026 5.83398C7.07941 5.83398 6.33325 6.58014 6.33325 7.50099C6.33325 8.42116 7.07941 9.16732 8.00026 9.16732C8.92043 9.16732 9.66659 8.42116 9.66659 7.50099Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99967 14.5C7.20069 14.5 3 11.0989 3 7.54219C3 4.75776 5.23807 2.5 7.99967 2.5C10.7613 2.5 13 4.75776 13 7.54219C13 11.0989 8.79866 14.5 7.99967 14.5Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

LocationSvg.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

LocationSvg.defaultProps = {
  color: "black",
  className: "w-[20px] h-[20px]",
};

export default LocationSvg;
