import * as yup from 'yup'

export const locationSchema = yup.object().shape({
    locationName: yup.string().required("Location Name is Required"),
    country: yup.string().required("Country is Required"),
    postalCode: yup.number().required("Postal Code is Required"),
    phoneNumber: yup.number().required("Phone Number is Required"),
    address: yup.string().required("Address is Required"),
    departments: yup.array().of(
        yup.object().shape({
            department: yup.string().required("Department is required"),
            jobPosition: yup
                .array()
                .of(yup.string())
                .min(1, "At least one job position is required")
                .required("At least one job position is required"),
        })
    ),
})