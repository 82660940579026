import React from "react";

function LocationTree({ location, background }) {
  return (
    <div className="inline-block">
      <div
        style={{
          backgroundColor: background,
        }}
        className={`w-[200px] text-white h-[50px] rounded-full flex justify-center items-center`}
      >
        <h1>{location}</h1>
      </div>
    </div>
  );
}

export default LocationTree;
