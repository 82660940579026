import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { AlertMessage } from "@bluesilodev/timhutcomponents";

import { onAlert, clear } from "utils/alert";
import { capitalize_first_letter } from "utils/common";

const AlertService = ({
  id
}) => {
  const [alert, setAlert] = useState({});
  const isMountedRef = useRef(null);

  useEffect(() => {
    isMountedRef.current = true;

    const subscription = onAlert(id).subscribe(notif => {
      if (isMountedRef.current) {
        setAlert(notif);
        setTimeout(() => setAlert({}), 4000);
      }
    });

    return () => {
      clear(id);
      subscription.unsubscribe();
      isMountedRef.current = false;
    };
  }, [id]);

  return (
    <div className="absolute top-10 right-10" style={{minWidth: "250px", zIndex: 999999}}>
      {
        alert.message && (
          <AlertMessage 
            type={alert.type}
            title={capitalize_first_letter(alert.type)}
            message={alert.message}
            dismissSecond={4000}
          />
        )
      }
    </div>
  )
}

AlertService.propTypes = {
  id: PropTypes.string,
};

AlertService.defaultProps = {
  id: "default-alert",
};

export default AlertService;