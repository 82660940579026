import { useQuery, useMutation } from '@tanstack/react-query'
import { customAxios } from "utils/axios";
import { getLocalStorage } from "utils/common";

const API = `/api/resignNotice`;
const { companyID } = getLocalStorage();

export const useGetResignNotice = ({ onError, onSuccess }) => {
    return useQuery({
        queryKey: ["getAllresignNotice"],
        queryFn: async () => {
            const res = await customAxios.get(`${API}/`)
            return res.data;
        },
        onError,
        onSuccess,
    },)
}

export const usePostResignNotice = ({ onError, onSuccess }) => {
    return useMutation({
        queryFn: async (body) => {
            const res = await customAxios.post(`${API}/`, body)
            return res.data;
        },
        onError,
        onSuccess
    })
}

export const useGetOneResignNotice = ({ onError, onSuccess }) => {
    return useQuery({
        queryKey: ["getOneResignNotice"],
        queryFn: async () => {
            const res = await customAxios.get(`${API}/api/${companyID}`)
            return res.data;
        },
        onError,
        onSuccess
    })
}

export const useUpdateResignNotice = ({ onError, onSuccess }) => {
    return useMutation({
        queryFn: async (body) => {
            const res = await customAxios.get(`${API}/${companyID}`, body)
            return res.data;
        },
        onError,
        onSuccess
    })
}

export const useDeleteResignNotice = ({ onError, onSuccess }) => {
    return useMutation({
        queryFn: async () => {
            const res = await customAxios.delete(`${API}/${companyID}`)
            return res.data;
        },
        onError,
        onSuccess
    })
}