import React from "react";
import PropTypes from "prop-types";

function FiSmartphone({ color, className, ...props }) {
  return (
    <svg
      className={`${className}`}
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.9585 1.58203H6.04183C5.16738 1.58203 4.4585 2.29091 4.4585 3.16536V15.832C4.4585 16.7065 5.16738 17.4154 6.04183 17.4154H13.9585C14.8329 17.4154 15.5418 16.7065 15.5418 15.832V3.16536C15.5418 2.29091 14.8329 1.58203 13.9585 1.58203Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 14.25H10.0079"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

FiSmartphone.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

FiSmartphone.defaultProps = {
  color: "black",
  className: "w-[20px] h-[20px]",
};

export default FiSmartphone;
