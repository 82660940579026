import React from "react";
import PropTypes from "prop-types";

function FiUserX({className, color, ...props}) {
  return (
    <svg
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <g clipPath="url(#clip0_192_8716)">
        <path
          d="M10.6667 14.5V13.1667C10.6667 12.4594 10.3858 11.7811 9.8857 11.281C9.3856 10.781 8.70733 10.5 8.00008 10.5H3.33341C2.62617 10.5 1.94789 10.781 1.4478 11.281C0.9477 11.7811 0.666748 12.4594 0.666748 13.1667V14.5"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 5.83398L15.3333 9.16732"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.3333 5.83398L12 9.16732"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.66667 7.83333C7.13943 7.83333 8.33333 6.63943 8.33333 5.16667C8.33333 3.69391 7.13943 2.5 5.66667 2.5C4.19391 2.5 3 3.69391 3 5.16667C3 6.63943 4.19391 7.83333 5.66667 7.83333Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_192_8716">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

FiUserX.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

FiUserX.defaultProps = {
  color: "black",
  className: "w-[20px] h-[20px]",
};

export default FiUserX;
