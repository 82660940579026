import React from "react";

function PreviumSvg() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 3.5C18.1646 3.49998 18.3266 3.54058 18.4718 3.61821C18.6169 3.69583 18.7406 3.80808 18.832 3.945L18.894 4.053L21.894 10.053C21.9737 10.2121 22.0095 10.3896 21.9977 10.5671C21.9859 10.7446 21.927 10.9158 21.827 11.063L21.743 11.169L12.77 21.142C12.6641 21.2707 12.5272 21.3704 12.3723 21.4318C12.2173 21.4932 12.0493 21.5142 11.884 21.493L11.771 21.474C11.5633 21.4275 11.3765 21.3145 11.239 21.152L2.257 11.17C2.13794 11.0379 2.05627 10.8764 2.02042 10.7022C1.98456 10.528 1.9958 10.3474 2.053 10.179L2.106 10.054L5.10599 4.054C5.17959 3.90681 5.28837 3.78007 5.42269 3.685C5.55701 3.58993 5.71271 3.52947 5.87599 3.509L5.99999 3.5H18ZM14.576 11.5H9.42299L12 17.842L14.576 11.5ZM18.753 11.5H16.735L15.074 15.588L18.753 11.5ZM7.26399 11.5H5.24599L8.92399 15.587L7.26399 11.5ZM8.62199 5.5H6.61799L4.618 9.5H7.32199L8.62199 5.5ZM13.273 5.5H10.726L9.42699 9.5H14.572L13.273 5.5ZM17.381 5.5H15.377L16.677 9.5H19.381L17.381 5.5Z"
        fill="url(#paint0_linear_1841_154064)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1841_154064"
          x1="11.9999"
          y1="3.5"
          x2="11.9999"
          y2="21.501"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8812A" />
          <stop offset="1" stopColor="#F56E22" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default PreviumSvg;
