import React from "react";
import { Button, SelectMultipleCheckbox } from "@bluesilodev/timhutcomponents";
import { FiDownload } from "components/icons";
import HandleRightLeft from "./HandleLeftRight";
import { Outlet } from "react-router-dom";
import { setOptionVal } from "store/comunitySlice";
import { useDispatch, useSelector } from "react-redux";
import { useDownloadExcel } from "service/userAPI";
import { useQueryClient } from "@tanstack/react-query";

function ComunityPage() {
  const navOptions = [
    {
      label: "Nationality",
      value: "Nationality",
    },
    {
      label: "Gender",
      value: "Gender",
    },
    {
      label: "Age",
      value: "Age",
    },
    {
      label: "Marital Status",
      value: "Marital Status",
    },
    {
      label: "Religion",
      value: "Religion",
    },
    {
      label: "Department",
      value: "Department",
    },
    {
      label: "Hobbies",
      value: "Hobbies",
    },
    {
      label: "Languages",
      value: "Languages",
    },
    {
      label: "Salary",
      value: "Salary",
    },
    {
      label: "Length Of",
      value: "Length Of",
    },
  ];
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  // DATA FOR EXPORT FROM REDUX
  const dataExcel = useSelector((state) => state.comunitySlice.excelData);

  const handleOptionChange = (selectedOptions) => {
    dispatch(setOptionVal(selectedOptions));
  };

  // DOWNLOAD API
  const { mutateAsync, isPending: pendingDownload } = useDownloadExcel({
    onSuccess: (data) => {
      queryClient.invalidateQueries("downloadExcel");
    },
    onError: (err) => {
      console.log("err : ", err);
    },
  });

  if (pendingDownload) {
    return <h1>Loading...</h1>;
  }

  const exportToExcel = async () => {
    const dataFromRedux = dataExcel.payload;

    try {
      // send data to server
      const responseData = await mutateAsync(dataFromRedux);

      // ask to user for choice location directory
      const fileHandle = await window.showSaveFilePicker({
        suggestedName: "comunity.xlsx",
        types: [
          {
            description: "Excel files",
            accept: {
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                [".xlsx"],
            },
          },
        ],
      });

      // write data to file you choice
      const writable = await fileHandle.createWritable();
      await writable.write(responseData); // write blob to the file
      await writable.close();
    } catch (error) {
      return;
    }
  };

  return (
    <div className="pt-10 overflow-x-hidden">
      <div className="flex gap-1 justify-between z-50 w-full ">
        <div className="w-[20%]">
          <SelectMultipleCheckbox
            options={navOptions}
            setDisplayValue={handleOptionChange}
            title={"Custom Filter"}
          />
        </div>

        <Button
          onClick={exportToExcel}
          style="solid"
          label={
            <div className="flex gap-2">
              <FiDownload color={"white"} />
              <div>Export</div>
            </div>
          }
          className={"w-[200px]"}
        />
      </div>
      <HandleRightLeft />

      <div className="w-full mt-24 flex justify-between items-center">
        <Outlet />
      </div>
    </div>
  );
}

export default ComunityPage;
