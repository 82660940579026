import { createSlice } from '@reduxjs/toolkit'

const comunitySlice = createSlice({
    name: "comunity",
    initialState: {
        option: [],
        excelData: []
    },
    reducers: {
        setOptionVal(state, action) {
            state.option = action.payload
        },
        setDataPostExcel(state, action) {
            state.excelData = action
        }
    }
})

export const { setOptionVal, setDataPostExcel } = comunitySlice.actions;
export default comunitySlice.reducer;