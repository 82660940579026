import React from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useParams, useNavigate } from "react-router-dom";
import { formatCalendarDate } from "configures/formatDate";

import { useGetEventById, useUpdateEvent } from "service/eventAPI";
import { alertSuccess, alertError } from "utils/alert";

import FormEventShared from "pages/shared/formEvent";

import { editEventSchema } from "./editSchema";

function EditEvent() {
  const queryClient = useQueryClient();
  const { uId } = useParams();
  const navigate = useNavigate();

  const { data, isPending: pendingGet } = useGetEventById(uId, {
    onSuccess: () => {
      queryClient.invalidateQueries("getEventById");
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const { mutate, isPending: pendingUpdate } = useUpdateEvent(uId, {
    onSuccess: () => {
      queryClient.invalidateQueries("getEvent");
      alertSuccess("Updated Success");
      navigate("/event");
    },
    onError: () => {
      alertError("Something wrong!");
    },
  });

  const handleSubmitForm = (val) => {
    const [day, month, year] = val.startDate.split("/");
    const [dd, mm, yy] = val.endDate.split("/");

    const startDate = new Date(year, month - 1, day);
    const endDate = new Date(yy, mm - 1, dd);

    const form = new FormData();
    form.append("nameEvent", val.nameEvent);
    form.append("startDate", startDate);
    form.append("endDate", endDate);
    form.append("isCompanyHoliday", val.isCompanyHoliday);
    form.append("posterAttachment", val.posterAttachment);
    form.append("duration", val.duration);
    form.append("note", val.note);
    form.append("location", val.location);

    mutate(form);
  };

  if (pendingGet || pendingUpdate) {
    return <div>Loading...</div>;
  }

  const dataEvent = data?.data;

  const initial = {
    nameEvent: "",
    startDate: "",
    endDate: "",
    duration: "",
    isCompanyHoliday: false,
    posterAttachment: [],
    note: "",
    location: "",
  };

  const setToApi = {
    ...initial,
    nameEvent: dataEvent?.nameEvent,
    startDate: formatCalendarDate(dataEvent?.startDate),
    endDate: formatCalendarDate(dataEvent?.endDate),
    isCompanyHoliday: dataEvent?.isCompanyHoliday,
    posterAttachment: Array.isArray(dataEvent?.posterAttachment)
      ? dataEvent.posterAttachment
      : [],
    duration: dataEvent?.duration,
    note: dataEvent?.note,
    location: dataEvent?.location,
  };

  return (
    <>
      <FormEventShared
        initialValues={setToApi}
        handleSubmitForm={handleSubmitForm}
        validationSchema={editEventSchema}
      />
    </>
  );
}

export default EditEvent;
