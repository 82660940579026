import React, { useState } from "react";
import { Accordion, UploadImage } from "@bluesilodev/timhutcomponents";
import { ChevronSvg } from "components/icons";

function ProfilePicture({
  handleBlur,
  values,
  errors,
  touched,
  setFieldValue,
  fieldPhoto,
}) {
  const [photo, setPhoto] = useState(null);

  // GET THE IMAGE
  const handleChangeImg = (event) => {
    const file = event.currentTarget.files[0];

    if (file) {
      const photo = URL.createObjectURL(file);

      setPhoto(photo);
      setFieldValue("photo", file);
    }
  };

  const handleDeleteImg = () => {
    setFieldValue("photo", "");
  };

  return (
    <div>
      <div className="w-full">
        <Accordion
          icons={[]}
          children={
            <UploadImage
              textSizeLimit={
                "For Best Resolution is 1366 x 1366px, Max upload image size is 2MB, Supported files: .jpg, .png "
              }
              // name="photo"
              id="photo"
              image={!photo ? values.photo : photo}
              onChange={handleChangeImg}
              handleDeleteImg={handleDeleteImg}
              accept="image/jpeg, image/jpg, image/png"
              onBlur={handleBlur}
              error={
                <div className="text-red-500">
                  {errors.photo && touched.photo && errors.photo}
                </div>
              }
            />
          }
          title={"Profile Picture"}
        />
      </div>
    </div>
  );
}

export default ProfilePicture;
