import React from "react";

function UserAddIcon({ className, ...props }) {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
      {...props}
    >
      <ellipse
        cx="8.50016"
        cy="5.33464"
        rx="2.66667"
        ry="2.66667"
        stroke="#FDFDFD"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.92237 9.6875C6.79071 9.76847 5.69251 10.0869 4.7545 10.6267C3.6581 11.2577 2.80511 12.18 2.40671 13.2952C2.2209 13.8152 2.49189 14.3875 3.01198 14.5733C3.53207 14.7591 4.10431 14.4881 4.29012 13.968C4.49946 13.3821 4.98562 12.8013 5.75209 12.3602C6.0802 12.1714 6.4451 12.0171 6.83492 11.9024C6.86346 11.0113 7.28058 10.2184 7.92237 9.6875Z"
        fill="#FDFDFD"
      />
      <path
        d="M12.5 9.33203L12.5 14.6654"
        stroke="#FDFDFD"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <path
        d="M15.1665 12L9.83317 12"
        stroke="#FDFDFD"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default UserAddIcon;
