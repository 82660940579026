import React from "react";
import PropTypes from "prop-types";

function FiMail({ color, className, ...props }) {
  return (
    <svg
      className={`${className}`}
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.66683 3.16797H16.3335C17.2043 3.16797 17.9168 3.88047 17.9168 4.7513V14.2513C17.9168 15.1221 17.2043 15.8346 16.3335 15.8346H3.66683C2.796 15.8346 2.0835 15.1221 2.0835 14.2513V4.7513C2.0835 3.88047 2.796 3.16797 3.66683 3.16797Z"
        stroke="#201140"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.9168 4.75L10.0002 10.2917L2.0835 4.75"
        stroke="#201140"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

FiMail.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

FiMail.defaultProps = {
  color: "black",
  className: "w-[20px] h-[20px]",
};

export default FiMail;
