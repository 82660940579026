import { useQuery } from '@tanstack/react-query'
import { customAxios } from "utils/axios";

const API = `/api/user/comunity`;

// FILTER COMUNITY CLUB
export const useGetComunity = (options) => {

    const { hobby, gender, nationality, department, languages, religion, maritalStatus, age, ...queryOptions } = options

    async function fetchData() {
        let queryParams = "";

        // Mengecek apakah parameter tidak kosong, jika kosong, tidak ditambahkan ke URL
        if (hobby) queryParams += `hobby=${hobby}&`;
        if (gender) queryParams += `gender=${gender}&`;
        if (department) queryParams += `department=${department}&`;
        if (nationality) queryParams += `nationality=${nationality}&`;
        if (languages) queryParams += `languages=${languages}&`;
        if (religion) queryParams += `religion=${religion}&`;
        if (maritalStatus) queryParams += `maritalStatus=${maritalStatus}&`;
        if (age) queryParams += `age=${age}&`;

        // Menghapus tanda '&' di akhir URL jika diperlukan
        if (queryParams.endsWith('&')) {
            queryParams = queryParams.slice(0, -1);
        }

        const url = queryParams ? `${API}?${queryParams}` : API;

        try {
            const res = await customAxios.get(url);

            return res.data;
        } catch (error) {
            console.error("Error fetching data: ", error); // Debug: Log error
            throw error;
        }
    }

    const queryKey = ["getComunity"];
    if (hobby) queryKey.push(`hobby=${hobby}`);
    if (gender) queryKey.push(`gender=${gender}`);
    if (department) queryKey.push(`department=${department}`);
    if (nationality) queryKey.push(`nationality=${nationality}`);
    if (languages) queryKey.push(`languages=${languages}`);
    if (religion) queryKey.push(`religion=${religion}`);
    if (maritalStatus) queryKey.push(`maritalStatus=${maritalStatus}`);
    if (age) queryKey.push(`age=${age}`);

    return useQuery({
        queryKey: queryKey.length > 1 ? queryKey : ["getComunity"],
        queryFn: fetchData,
        ...queryOptions
    },
    )
}

