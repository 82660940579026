import React from "react";
import { Button, Accordion, InputNumber } from "@bluesilodev/timhutcomponents";

import { useGetResignNotice } from "service/resignNotice";

import { FiSaveSvg, ChevronSvg } from "components/icons";

function ResignNotice() {
  const { data, isPending } = useGetResignNotice({
    onSuccess: () => {},
  });

  if (isPending) {
    return <div>Loading...</div>;
  }

  return (
    <div className="w-full overflow-y-auto">
      <div className="w-full flex justify-end">
        <Button
          style={"solid"}
          className={"w-[200px]"}
          label={
            <div className="flex gap-2">
              <FiSaveSvg color={"white"} />
              <div>Save</div>
            </div>
          }
        />
      </div>

      <div className="py-10">
        <Accordion
          title={"Resign Notice Period"}
          icons={[{ icon: <ChevronSvg /> }]}
          children={
            <div className="w-[80%] pt-5">
              <div className="w-full flex flex-col gap-2">
                <div className="flex justify-between items-center">
                  <h1>Supervisor Level</h1>
                  <InputNumber
                    placeholder={"Supervisor Level"}
                    name={"supervisor"}
                    label={"Notice Period"}
                    unit={"month"}
                    
                  />
                </div>

                <div className="flex justify-between items-center ">
                  <h1>Employee Level</h1>
                  <InputNumber
                    placeholder={"Employee Level"}
                    name={"employee"}
                    label={"Notice Period"}
                    unit={"month"}
                  />
                </div>
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
}

export default ResignNotice;
