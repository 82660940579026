import { useState } from "react";
import { ModalDialog } from "@bluesilodev/timhutcomponents";
import { Tree, TreeNode } from "react-organizational-chart";
import DragLeftAndRight from "./DragLeftAndRight";
import { useGetOrganization } from "service/organizationApi";
import { useQueryClient } from "@tanstack/react-query";

import CardTree from "./CardTree";
import LocationTree from "./LocationTree";
import ChildModalDialog from "./ChildModalDialog";

function TreeOrganization({ location, department }) {
  const queryCLient = useQueryClient();
  const [showModal, setShowModal] = useState(false);
  const [dataModal, setDataModal] = useState({});

  const { data, isPending } = useGetOrganization(
    {
      location: location,
      department: department,
    },
    {
      onSuccess: () => {
        queryCLient.invalidateQueries("getOrganization"); // Menghapus cache dan memicu pengambilan ulang data
      },
      onError: (err) => {
        console.log("err: ", err);
      },
    }
  );

  if (isPending) {
    return <div>Loading ...</div>;
  }

  const filterReportNull = data && data?.data?.filterReportNull;
  const hierarchyByLocation = data && data?.data?.hierarchyByLocation;

  // CREATE FUNCTION REKURSIVE => MAP BY YOUR SELF
  const renderTreeNodes = (data) => {
    if (data) {
      return data.map((item, index) => (
        <TreeNode
          key={index}
          label={<CardTree data={item} handleShowModal={handleShowModal} />}
        >
          {item.subordinates &&
            item.subordinates.length > 0 &&
            renderTreeNodes(item.subordinates)}
        </TreeNode>
      ));
    }
  };

  const HierarchyTree = ({ hierarchyByLocation }) => {
    if (hierarchyByLocation) {
      return hierarchyByLocation
        .map((item, index) => {
          if (item.data.length > 0) {
            return (
              <TreeNode
                key={index}
                label={
                  <LocationTree
                    location={item.location}
                    background={item.color}
                  />
                }
              >
                {renderTreeNodes(item.data)}
              </TreeNode>
            );
          }
          return null; // Return null for items with empty data arrays
        })
        .filter((node) => node !== null);
    }
  };

  // SET DATA AND SHOW MODAL
  const handleShowModal = (data) => {
    setDataModal(data);

    setShowModal(true);
  };

  return (
    <div className="w-full select-none mt-8">
      <DragLeftAndRight>
        <Tree
          lineWidth={"2px"}
          lineColor={"green"}
          lineBorderRadius={"10px"}
          label={
            <LocationTree
              location={"Organization Structure"}
              background={"orange"}
            />
          }
        >
          {filterReportNull &&
            filterReportNull.map((item, index) => (
              <TreeNode
                key={index}
                label={
                  <LocationTree
                    location={item.location}
                    background={item.color}
                  />
                }
              >
                {item.data.map((item, index) => (
                  <TreeNode
                    key={index}
                    label={
                      <CardTree data={item} handleShowModal={handleShowModal} />
                    }
                  />
                ))}
              </TreeNode>
            ))}

          <HierarchyTree hierarchyByLocation={hierarchyByLocation} />
        </Tree>
      </DragLeftAndRight>

      {showModal && (
        <ModalDialog
          className={"w-[650px]"}
          title={"Employee Details"}
          onClose={() => setShowModal(false)}
        >
          <ChildModalDialog data={dataModal} setShowModal={setShowModal} />
        </ModalDialog>
      )}
    </div>
  );
}

export default TreeOrganization;
