import { useQuery } from '@tanstack/react-query'
import axios from 'axios';
import { getLocalStorage } from "utils/common";

const API = `${process.env.REACT_APP_LEAVE_API_URL}`;

const { config } = getLocalStorage();

// GET ALL API GLOBAL
export const useGetLeaveGlobal = ({ onSuccess, onError }) => {

    return useQuery({
        queryKey: ["globalLeave"], // Gunakan queryKey yang sudah dibuat
        queryFn: async () => {
            const res = await axios.get(`${API}/api/leave-type/get`, config)

            return res.data;
        },
        onSuccess,
        onError
    });
};


// GET SINGLE USER
export const useGetLeaveById = (uId, { onSuccess, onError }) => {
    return useQuery({
        queryKey: ["getLeaveDataID", uId],
        queryFn: async () => {
            const res = await axios.get(`${API}/api/leave-user/get-user-type/${uId}`, config)

            return res.data;
        },
        onSuccess,
        onError
    })
}



