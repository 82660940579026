function DateDisplay({ dateStr }) {
  if (!dateStr) {
    return <div>-</div>;
  }

  const date = new Date(dateStr);

  const day = date.getDate();
  const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
    date
  );
  const year = date.getFullYear();

  const formattedDate = `${day} ${month} ${year}`;

  return (
    <div>
      <div>{formattedDate}</div>
    </div>
  );
}

export default DateDisplay;
