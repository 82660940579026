import React from "react";
import PropTypes from "prop-types";

function Fi3User({ color, className, ...props }) {
  return (
    <svg
      className={`${className}`}
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.6611 8.6271C15.7655 8.47194 16.6158 7.5251 16.6181 6.37798C16.6181 5.24748 15.794 4.31015 14.7134 4.13281"
        stroke={{color}}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.1182 11.2812C17.1877 11.4412 17.9342 11.8156 17.9342 12.5875C17.9342 13.1187 17.5827 13.4639 17.0143 13.6808"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.91041 11.6094C7.366 11.6094 5.19287 11.9949 5.19287 13.5347C5.19287 15.0737 7.35254 15.4703 9.91041 15.4703C12.4548 15.4703 14.6272 15.0887 14.6272 13.5482C14.6272 12.0076 12.4683 11.6094 9.91041 11.6094Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.91012 9.41003C11.5797 9.41003 12.9335 8.05707 12.9335 6.38666C12.9335 4.71703 11.5797 3.36328 9.91012 3.36328C8.24049 3.36328 6.88674 4.71703 6.88674 6.38666C6.88041 8.05074 8.22387 9.40449 9.88795 9.41003H9.91012Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.15866 8.6271C4.05349 8.47194 3.20404 7.5251 3.20166 6.37798C3.20166 5.24748 4.02579 4.31015 5.10641 4.13281"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.70134 11.2812C2.6318 11.4412 1.88525 11.8156 1.88525 12.5875C1.88525 13.1187 2.23675 13.4639 2.80517 13.6808"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

Fi3User.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

Fi3User.defaultProps = {
  color: "black",
  className: "w-[20px] h-[20px]",
};

export default Fi3User;
